import React, { Component, Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import {connect} from "react-redux";
import { required, onlyNumbers } from "../../shared/Validation";
import { renderTextField  } from "../../shared/renderField";
import { Row, Col} from "reactstrap";
import { setLoader, setToastr, setConfirmBox } from "../../../actions/Application";
import {getFota, deleteFota, updateFota} from "../../../api/admin/fota";
import {FaPencilAlt, FaTrashAlt} from 'react-icons/fa'
import Pagination from "../../shared/Pagination";
import SearchFormComponent from "../custom-map/SearchFormComponent";
import Moment from "react-moment";

/** importnig constants */
import { LIMIT } from "../../../constants/Constants";

let form = "updateFotaForm";

class ListFotaComponent extends Component{

    constructor(props){
        super(props);

        this.state = {
            maps: [],
            activePage: 1,
            total: 0,
            edit: null,
            qs: {}
        }
    }

    componentWillMount(){
        this.getFotaData({page:1});   
    }

    componentWillReceiveProps(newProps){
        if(newProps.ack){
            this.getFotaData({page: this.state.activePage, qs:this.state.qs});
        }
    }

    confirmnDelete = (user) => {
        this.props.setConfirmBox({ modal: { open: true, data: user, title: "Delete Record", content: `Do you really want to delete this record?`, done: this.deleteRecord }});
    }

    deleteRecord = (item) =>{
        /** show loader */
        this.props.setLoader({loader:{open: true}});

        return new Promise((resolve, reject) => {
            deleteFota(item.listId)
                .then(result => {
                    /** resolve promise */
                    /** close loader */
                    this.props.setLoader({ loader: { open: false } });
                    /** dispatch action to reload data */
                    this.getFotaData({page: this.state.activePage, qs:this.state.qs});
                    resolve({ toastr: { message: "Record has been deleted.", type: "success" } });
                })
                .catch(error => {
                    /** close loader */
                    this.props.setLoader({ loader: { open: false } });
                    setToastr({ toastr: { message: "This request couldn't be completed. Try again later.", type: "error" } });
                    /** reject promise */
                    reject(false);
                })
        });
    }

    getFotaData = (req) => {
        this.props.setLoader({ loader: {open:true}});

        getFota(req)
        .then(result => {
            this.props.setLoader({ loader: {open:false}});

            this.setState({
                maps: (result.data && result.data.listFotaRecords) || [],
                activePage: this.state.activePage || 1,
                total: (result.data.countResult && result.data.countResult.totalCounts) ? result.data.countResult.totalCounts : this.state.total 
            });
        })
        .catch(err => this.props.setLoader({ loader: {open:false}}));
    }

    onPageChange = (page) => {
        this.setState({activePage: page || 1}, () => {
            this.getFotaData({page, qs:this.state.qs});
        });  
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.initializeTab != this.props.initializeTab) {   
            this.onPageChange(1);
        }
    }

    editRecord = item => {
        this.setState({ edit: item });
        this.props.initialize(item);
        this.props.ifEditing(true);
        window.scrollTo(0, 0);
    }


    cancelUpdate = () => {
        this.setState({ edit: null });
        this.props.ifEditing(false);
        this.props.initialize({machineNum:null, model:null, map1Id:null, map2Id:null});
    }

    updateCustomFota = (values) => {
        let {machineNum, model, version, listId} = values;
        
        this.props.setLoader({ loader: {open: true}});
        updateFota({machineNum, model, version}, listId)
        .then(response => {
            if(response.data){
                this.props.setToastr({ toastr: { message:"Equipment has been updated.", type:"success" }});
                this.setState({edit: null});
                this.props.initialize({machineNum:null, model:null, map1Id:null, map2Id:null});
                this.props.ifEditing(false);
                this.getFotaData({page: this.state.activePage, qs:this.state.qs});
            }else{
                throw new Error();
            }
            this.props.setLoader({ loader: {open: false}});
        })
        .catch(error => {
            this.props.setToastr({toastr:{type: "error", message:error || "Something went wrong."}});
            this.props.setLoader({ loader: {open: false}});            
        })
    }

    onSubmit = (values) => {
        values.map1Id = parseInt(values.map1Id);
        values.map2Id = parseInt(values.map2Id);

        if(this.state.edit){
            /* update the record */
            this.updateCustomFota(values);
            return false;
        }
    }

    onSearch = (qs) => {
        if(!Object.keys(qs).length) return false;

        this.props.setLoader({ loader: {open: true}});
        this.setState({ qs });

        this.getFotaData({page:1, qs });
    }

    clearSearch = () => {
        this.setState({qs:{}}); 
        this.getFotaData({page:1});
    }

    render(){
        const { handleSubmit, submitting} = this.props, {maps, activePage, total, edit} = this.state;
        return(
            <Fragment>
                

                {edit && <form name={form} id={form} autoComplete='off' onSubmit={handleSubmit(this.onSubmit)}>
                    <Row className="members align-items-center form-srch">
                        <Col sm="12">

                            <div className="row profile-asset">

                                <div className="col-sm-3">Model <span className='required'>*</span>
                                    <Field name="model"  component={renderTextField} className='form-control' type="text" validate={[required]}/>
                                </div>

                                <div className="col-sm-3">Machine Number <span className='required'>*</span>
                                    <Field name="machineNum"  component={renderTextField} className='form-control' type="text" validate={[required]}/>
                                </div>
                                
                                <div className="col-sm-3">Version<span className='required'>*</span>
                                    <Field name="version" component={renderTextField} className='form-control' type="text" validate={[required, onlyNumbers]}/>
                                </div>

                                <div className="col-sm-1">
                                    <button type="submit" className="btn btn-primary srch-btn" disabled={submitting}>Update</button>
                                </div>

                                <div className="col-sm-2"> &nbsp;&nbsp;&nbsp;
                                    <button type="button" className="btn btn-danger srch-btn" disabled={submitting} onClick={() => this.cancelUpdate()}>Cancel</button> 
                                </div>
                            </div>
                        </Col>
                    </Row>
                </form>}

                <br />

                <h4>Search Equipment</h4>
                <SearchFormComponent onSearch={this.onSearch} qs={this.state.qs} clearSearch={this.clearSearch} getEquipmentList={this.props.getEquipmentList} searchItems={this.props.searchItems} goToItem={this.props.goToItem} keyword={this.props.keyword} fieldType={this.props.fieldType}/>

                <table className="tabledashboard table table-striped table-advance table-hover m-top-30">
                    <thead>
                        <tr>
                            <th>Model</th>
                            <th>Machine Number</th>
                            <th>Version</th>
                            <th>Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(maps) && maps.length>0 && maps.map((item,i) => (
                            <tr key={item.model+i+1}>
                                <td>{item.model || "NA"}</td>
                                <td>{item.machineNum || "NA"}</td>
                                <td>{item.version || "NA"}</td>
                                <td><Moment format="DD MMM YYYY HH:mm:ss">{item.date}</Moment></td>
                                <td>
                                    <FaPencilAlt onClick={() => this.editRecord(item)}/>
                                    &nbsp; 
                                    &nbsp; 
                                    <FaTrashAlt onClick={() => this.confirmnDelete(item)}/>
                                </td>
                            </tr>
                        ))}

                        {!maps.length && <tr><td align="center" colSpan="6">No Data Found</td></tr>}
                        
                    </tbody>
                </table>

                <Pagination 
                    limit={LIMIT}
                    total={total}
                    activePage={activePage}
                    onChange={this.onPageChange}
                    data={maps || []}
                />
            </Fragment>
        );
    }
}


let formComponent = reduxForm({ form })(ListFotaComponent);
let mapDispatchToProps = { setLoader, setToastr, setConfirmBox };

export default connect(null, mapDispatchToProps)(formComponent);