import React, { Component, Fragment } from "react";
import { Field, reduxForm, reset ,change} from "redux-form";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { required, onlyNumbers } from "../../shared/Validation";
import { renderTextField } from "../../shared/renderField";
import { Row, Col,Card, CardBody } from "reactstrap";
import { saveListFota } from "../../../api/admin/fota";
import { setLoader, setToastr } from "../../../actions/Application";
import ListFotaComponent from "./ListFotaComponent";
import { getFotaEquipmentList,getAllFotaEquipmentList } from "../../../actions/admin/fota"

let form = "FotaForm";

class AddFotaComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isEdit: false,
            ack: false,
            searchItems: [],
            keyword: "",
            fieldType: ""
        }
        this.getEquipmentList = this.getEquipmentList.bind(this);
        this.goToItem = this.goToItem.bind(this);
    }
    getEquipmentList(e){
        let fieldType = e.target.id
        let { value } = ReactDOM.findDOMNode(document.getElementById(e.target.id)), { searchEquipmentList,allEquipmentList } = this.props, regex = new RegExp(value.trim(), 'i');
        let filtered = [],models=[], machines=[];
        if (fieldType && fieldType.includes('search')) {
            filtered = searchEquipmentList.filter(e => {
                if (!models.includes(e.model.toString()) && fieldType === 'searchmodel') {
                    if (regex.test(e.model.toString())) {
                        models.push(e.model.toString());
                        return e;
                    }
                } else if (!machines.includes(e.machineNum.toString()) && fieldType === 'searchmachineNum') {
                    if (regex.test(e.machineNum)) {
                        machines.push(e.machineNum.toString())
                        return e;
                    }
                }
            });
        }else {
            filtered = allEquipmentList.filter(e => {
                if (!models.includes(e.model.toString()) && fieldType === 'model') {
                    if (regex.test(e.model.toString())) {
                        models.push(e.model.toString());
                        return e;
                    }
                } else if (!machines.includes(e.machineNum.toString()) && fieldType === 'machineNum') {
                    if (regex.test(e.machineNum.toString())) {
                        machines.push(e.machineNum.toString())
                        return e;
                    }
                }
            });
        }
        /*if (fieldType && fieldType.includes('search')) {
            filtered = searchEquipmentList.filter(e => {
                if (!models.includes(e.model.toString()) && fieldType === 'searchmodel') {
                    if (regex.test(e.model.toString())) {
                        models.push(e.model.toString());
                        return e;
                    }
                } else if (!machines.includes(e.machineNum.toString()) && fieldType === 'searchmachineNum') {
                    if (regex.test(e.machineNum.toString())) {
                        machines.push(e.machineNum.toString())
                        return e;
                    }
                }
            });
        }*/
        
        this.setState({ searchItems: filtered, keyword: value, fieldType: fieldType });
    }
    componentDidMount() {
        this.props.getFotaEquipmentList();
        this.props.getAllFotaEquipmentList();
    }
    goToItem = (attr, fieldType) => {
        //setting value
        fieldType = this.state.fieldType;
        if(fieldType && fieldType.includes('search')){
            fieldType = fieldType.replace('search','');
        this.props.dispatch(change('SearchCustomMapForm', fieldType, attr));
        }else{
            this.props.dispatch(change('FotaForm', fieldType, attr));
            
    }
    this.setState({ keyword: "", searchItems: [], fieldType: "" });
    }

    ifEditing = (isEdit) => {
        this.setState({ isEdit });
    }

    onSubmit = (values) => {
        values.version = +values.version;

        this.props.setLoader({ loader: { open: true } });
        saveListFota(values)
            .then(response => {
                if (response.data) {
                    this.props.setToastr({ toastr: { message: "Equipment has been added.", type: "success" } });
                    this.setState({ ack: true }, () => this.setState({ ack: false }));
                    //this.props.setTab(1);
                    this.props.dispatch(reset(form));
                } else {
                    throw new Error();
                }
                this.props.setLoader({ loader: { open: false } });
            })
            .catch(error => {
                this.props.setToastr({ toastr: { type: "error", message: error || "Something went wrong." } });
                this.props.setLoader({ loader: { open: false } });
            })
    }
   
    render() {
        const { handleSubmit, submitting,initializeTab} = this.props, { isEdit, ack,searchItems,fieldType,keyword } = this.state;


        return (
            <Fragment>
                {!isEdit && <form name="FotaForm" id="FotaForm" onSubmit={handleSubmit(this.onSubmit)} autoComplete='off'>
                    <Row className="members align-items-center form-srch">

                        <Col sm="12">

                            <div className="row profile-asset">

                                <div className="col-sm-3">Model <span className='required'>*</span>
                                    <Field name="model" id='model' component={renderTextField} className='form-control' type="text" validate={[required]} onChange={this.getEquipmentList} />
                                    {keyword.length > 0 && searchItems.length > 0 && fieldType === 'model' &&
                                        <div className='search-area'>
                                            <Card className='search-card'>
                                                <CardBody style={{overflowY:"auto" , maxHeight:"300px"}}>
                                                    <ul className="list-group list-group-flush">
                                                        {searchItems.map((item, i) => {
                                                            return <Item key={i} attr={{ item }} goToItem={this.goToItem} fieldType={'model'} />;
                                                        })}
                                                    </ul>
                                                </CardBody>
                                            </Card>
                                        </div>}
                                    {keyword.length > 0 && searchItems.length == 0 && fieldType === 'model' &&
                                        <div className='search-area'>
                                            <Card className='search-card'>
                                                <CardBody>
                                                    <ul className="list-group list-group-flush">
                                                        <li className="list-group-item pointer" >
                                                            <Row>
                                                                <Col sm='12' className='no-padd-lr'>
                                                                    <span>No record found</span>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                    </ul>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    }
                                </div>

                                <div className="col-sm-3">Machine Number <span className='required'>*</span>
                                    <Field name="machineNum" id='machineNum' component={renderTextField} className='form-control' type="text" validate={[required]} onChange={this.getEquipmentList} />
                                    {keyword.length > 0 && searchItems.length > 0 && fieldType === 'machineNum' && <div className='search-area'>

                                        <Card className='search-card'>
                                            <CardBody style={{overflowY:"auto" , maxHeight:"300px"}}>
                                                <ul className="list-group list-group-flush">
                                                    {searchItems.map((item, i) => {
                                                        return <Item key={i} attr={{ item }} goToItem={this.goToItem} fieldType={'machineNum'} />;
                                                    })}
                                                </ul>
                                            </CardBody>
                                        </Card>
                                    </div>}
                                    {keyword.length > 0 && searchItems.length == 0 && fieldType === 'machineNum' &&
                                        <div className='search-area'>
                                            <Card className='search-card'>
                                                <CardBody>
                                                    <ul className="list-group list-group-flush">
                                                        <li className="list-group-item pointer" >
                                                            <Row>
                                                                <Col sm='12' className='no-padd-lr'>
                                                                    <span>No record found</span>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                    </ul>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    }
                                </div>

                                <div className="col-sm-3">Version <span className='required'>*</span>
                                    <Field name="version" component={renderTextField} className='form-control' type="text" validate={[required, onlyNumbers]} />
                                </div>

                                <div className="col-sm-1">
                                    <button type="submit" className="btn btn-primary srch-btn" disabled={submitting}>Save</button>
                                </div>
                            </div>

                        </Col>
                    </Row>

                </form>}

                <ListFotaComponent ifEditing={this.ifEditing} ack={ack} getEquipmentList={this.getEquipmentList} searchItems={searchItems} goToItem={this.goToItem} keyword={keyword} fieldType={fieldType} initializeTab={initializeTab}/>
            </Fragment>
        );
    }
}

const Item = props => {
    let { attr, goToItem, fieldType } = props;
    var listOption = attr.item[fieldType];
    return (
        <li className="list-group-item pointer" onClick={() => goToItem(listOption, fieldType)} >
            <Row>
                <Col sm='12' className='no-padd-lr'>
                    <span>{listOption}</span> &nbsp;
                </Col>
            </Row>
        </li>
    );
}
let formComponent = reduxForm({ form })(AddFotaComponent);
let mapDispatchToProps = { setLoader, setToastr, getFotaEquipmentList,getAllFotaEquipmentList };
const mapStateToProps = state => {
    return ({
        searchEquipmentList: state.fota && state.fota.SearchCustomMapEqList && state.fota.SearchCustomMapEqList.data,
        allEquipmentList:state.fota && state.fota.newCustomMapEqList && state.fota.newCustomMapEqList.data

    });
}
export default connect(mapStateToProps, mapDispatchToProps)(formComponent);;
