import React, { Component, Fragment } from "react";
import { Field, reduxForm ,change} from "redux-form";
import { required } from "../../shared/Validation";
import ReactDOM from "react-dom";
import { renderTextField  } from "../../shared/renderField";
import { Row, Col,Card, CardBody  } from "reactstrap";
import "./style.scss";
import Pagination from "../../shared/Pagination";
/** importnig constants */
import { LIMIT } from "../../../constants/Constants";
import Moment from 'react-moment';
import { connect } from "react-redux";
import ItemSelection from "./ItemSelection";



class CustomMapListComponent extends Component{
    constructor(props){
        super(props);
        this.state = {values: null,
            searchItems: [],
            keyword: "",
            fieldType: "",
            activePage: 1
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
    }

    onSubmit(values){
        this.setState({values:values})
        this.props.onClick(values);
    }

    handlePageChange(page) {
        this.setState({ activePage: page });
        let values = this.state.values;
        if (values) {
            values.activePage = page;
            this.props.onClick(values);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.initializeTab != this.props.initializeTab) {
            this.setState({
                values: {
                    ...this.state.values, activePage: 1
                }
            })
            this.handlePageChange(1);
        }
    }

    getEquipmentList = (e) => {
        let fieldType = e.target.id
        let models=[], machines=[];
        let { value } = ReactDOM.findDOMNode(document.getElementById(e.target.id)), { mapEquipmentList } = this.props, regex = new RegExp(value.trim(), 'i');
        let filtered = mapEquipmentList.filter(e => {
            if (!models.includes(e.model.toString()) && fieldType === 'model') {
                if (regex.test(e.model.toString())) {
                    models.push(e.model.toString());
                    return e;
                }
            } else if (!machines.includes(e.machineNum.toString()) && fieldType === 'machineNum') {
                if (regex.test(e.machineNum)) {
                    machines.push(e.machineNum.toString())
                    return e;
                }
            }
        });
        this.setState({ searchItems: filtered, keyword: value, fieldType: fieldType });
    }
    goToItem = (attr, fieldType) => {
        //setting value
        this.props.dispatch(change('CustomMapForm', fieldType, attr));
        this.setState({ keyword: "", searchItems: [], fieldType: "" });
    }

    clearSearch() {
        this.setState({ values: null });
        this.props.clearSearch();
    }

    render(){
        let { maps, handleSubmit, submitting, total,dispatch, reset,clearSearch} = this.props;
        let {searchItems,fieldType,keyword,activePage} = this.state;
        return(
            <Fragment>
                <form name="CustomMapForm" id="CustomMapForm" autoComplete='off' onSubmit={handleSubmit(this.onSubmit)}>
                    <Row className="members align-items-center form-srch">
                        <Col sm="12">
                            <div className="row profile-asset">
                                <div className="col-sm-3">Model <span className='required'>*</span>
                                    <Field name="model" id="model" component={renderTextField} className='form-control' type="text" validate={[required]} onChange={this.getEquipmentList} />
                                    {keyword.length > 0 && searchItems.length > 0 && fieldType === 'model' &&
                                        <div className='search-area'>
                                            <Card className='search-card'>
                                                <CardBody style={{overflowY:"auto" , maxHeight:"300px"}}>
                                                    <ul className="list-group list-group-flush">
                                                        {searchItems.map((item, i) => {
                                                            return <ItemSelection key={i} attr={{ item }} goToItem={this.goToItem} fieldType={'model'} />;
                                                        })}
                                                    </ul>
                                                </CardBody>
                                            </Card>
                                        </div>}
                                    {keyword.length > 0 && searchItems.length == 0 && fieldType === 'model' &&
                                        <div className='search-area'>
                                            <Card className='search-card'>
                                                <CardBody>
                                                    <ul className="list-group list-group-flush">
                                                        <li className="list-group-item pointer" >
                                                            <Row>
                                                                <Col sm='12' className='no-padd-lr'>
                                                                    <span>No record found</span>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                    </ul>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    }
                                </div>

                                <div className="col-sm-3">Machine Number <span className='required'>*</span>
                                    <Field name="machineNum" id="machineNum" component={renderTextField} className='form-control' type="text" validate={[required]} onChange={this.getEquipmentList} />
                                    {keyword.length > 0 && searchItems.length > 0 && fieldType === 'machineNum' &&
                                        <div className='search-area'>
                                            <Card className='search-card'>
                                                <CardBody style={{overflowY:"auto" , maxHeight:"300px"}}>
                                                    <ul className="list-group list-group-flush">
                                                        {searchItems.map((item, i) => {
                                                            return <ItemSelection key={i} attr={{ item }} goToItem={this.goToItem} fieldType={'machineNum'} />;
                                                        })}
                                                    </ul>
                                                </CardBody>
                                            </Card>
                                        </div>}
                                    {keyword.length > 0 && searchItems.length == 0 && fieldType === 'machineNum' &&
                                        <div className='search-area'>
                                            <Card className='search-card'>
                                                <CardBody>
                                                    <ul className="list-group list-group-flush">
                                                        <li className="list-group-item pointer" >
                                                            <Row>
                                                                <Col sm='12' className='no-padd-lr'>
                                                                    <span>No record found</span>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                    </ul>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    }
                                </div>

                                <div>
                                    <button type="submit" className="btn btn-success srch-btn" disabled={submitting}>Search</button>&nbsp;&nbsp;&nbsp;
                                </div>
                                {maps &&
                                <div>
                                    <button type="button" className="btn btn-danger srch-btn" onClick={() => {
                                        dispatch(reset());
                                        this.clearSearch();
                                    }} disabled={submitting}>Clear Search</button>
                                </div>}
                            </div>
                        </Col>
                    </Row>
                </form>
                
                {maps && <table className="tabledashboard table table-striped table-advance table-hover m-top-30">
                    <thead>
                        <tr>
                            <th>Model</th>
                            <th>Machine Number</th>
                            <th>Map1</th>
                            <th>Map2</th>
                            <th>Map Type</th>
                            <th>Send Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {maps.length>0 && maps.map((item,i) => (
                            <tr key={item.model+i+1}>
                                <td>{item.model || "NA"}</td>
                                <td>{item.machineNum || "NA"}</td>
                                <td>{item.map1 || "NA"}</td>
                                <td>{item.map2 || "NA"}</td>
                                <td>{item.mapType || "NA"}</td>
                                
                                <td><Moment format="DD MMM, YYYY hh:mm A">{item.mapSentDate}</Moment></td>
                            </tr>
                        ))}

                        {!maps.length && <tr><td align="center" colSpan="6">No Data Found</td></tr>}
                        
                    </tbody>
                </table>}

                <Pagination 
                    limit={LIMIT}
                    total={total}
                    activePage={activePage}
                    onChange={this.handlePageChange.bind(this) }
                    data={maps || []}
                />
            </Fragment>
        );
    }
}

let CustomReduxForm = reduxForm({form: "CustomMapForm"})(CustomMapListComponent);


function mapStateToProps(state) {
   
    return ({
        equipmentList: state.sp ? state.sp.equipmentList : []

    });
}
export default connect(mapStateToProps, null)(CustomReduxForm);