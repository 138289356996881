export default {
    Dashboard:'डैशबोर्ड',
    myEquipment:'मेरे उपकरण',
    myequipments:'मेरे उपकरण',
    username: 'उपयोगकर्ता का नाम',
    userID:'उपयोगकर्ता की आईडी',
    password: 'पासवर्ड',
    rememberIdPassword: 'उपयोगकर्ता की आईडी और पासवर्ड याद रखें',
    login: 'लॉग इन करें',
    forgotPassword: 'पासवर्ड भूल गए?',
    latestEquipment: 'नवीनतम उपकरण',
    pleaseEnterYourLoginID:'कृपया अपना लॉगिन आईडी दर्ज करें',
    pleaseEnterYourLoginpassword:'कृपया अपना लॉगिन पासवर्ड दर्ज करे',
    numberPlate: 'नंबर प्लेट',
    type: 'प्रकार',
    model: 'मॉडल',
    machineno: 'मशीन नंo',
    machineSaleId: 'मशीन बिक्री क्रमांक',
    fuel: 'ईंधन',
    tractor:'ट्रैक्टर',
    contractDate:'कॉन्ट्रैक्ट तिथि',
    ContractExpiry:'संविदा समाप्ति',
  
    language : 'भाषा',
    title : 'शीर्षक',
    alert: 'चेतावनी',
    location: 'स्थान',
    error: 'त्रुटि',
    hourMeter: 'समय मीटर (घंटे)',
    updatedOn: 'अपडेट किया गया',
    occurredon : 'Occurred On',
    stateCountry: 'राज्य/राष्ट्र',
    emergencyContact : 'आपातकालीन संपर्क',
    emergencyContactNumber : ' आपातकालीन संपर्क नंबर',
    address: 'पता',
    hourmeter: 'समय मीटर (घंटे)',
    currentLocation: 'स्थान',
    equipmentType: 'उपकरण के प्रकार',
    editPicture: 'चित्र बदलें',
    save: 'सेव करें',
    cancel: 'रद्द करें',
    on:'चालू',
    off:'बंद',
    
    editSettings: 'सेटिंग बदलें',
    geoFencing: 'भौगोलिक सीमा',
    status: 'स्थिति',
    OperatingTime: 'काम करने का समय',
    on: 'खुला',
    off: 'बंद है',
    time: 'समय',
    oilingQuantity: 'आयलिंग क्वांटिटी',
    averageMileage: 'औसत लाभ',
    fuelConsumption: 'ईंधन की खपत (लिo)',
    averageSpeed: 'औसत गति (Km/h)',
    memo: 'मेमो',
    
    workdate: 'काम की तारीख',
    alertdate : 'चेतावनी दिनांक',
    reportDate: 'रिपोर्ट तिथि',
    urgency : 'उतावलापन',
    owner : 'मालिक',
    emergnecyConatct : 'आपातकालीन संपर्क',
    application : 'आवेदन',
    messageTitle : 'संदेश का शीर्षक',
    startOn : 'शुरुआत करना',
    endsOn : 'पर समाप्त होता है',
    description : 'विवरण',
    totalDistance: 'कुल दूरी की यात्रा',
    averagePmAmount : 'औसत पीएम राशि',
    averageDiffPmAmount : 'औसत मुश्किल। पीएम राशि',
    avDiffIntTemp : 'औसत मुश्किल। इंटरमीडिएट अस्थायी।',
    averageEngLdFac : 'औसत इंजन लोड फैक्टर',
    avDiffAshAmt : 'औसत डिफ ऐश राशि',
    avCoolantTemp : 'औसत कूलेंट अस्थायी',
    avActEnbSpeed : 'औसत वास्तविक इंजन की गति',
    avAshAmount : 'औसत ऐश राशि',
    first : 'प्रथम',
    second : 'दूसरा',
    third : 'तीसरा',
    supportresults : 'समर्थन परिणाम',
    supportComments : 'समर्थन टिप्पणियाँ',
    responders : 'उत्तर देनेवाला',
    datatime : 'दिनांक और समय',
    byModelMachineNo : 'मॉडल / मशीन नंबर द्वारा',
    /** Daily Work Words */
    manageDailyWork: "दैनिक कार्य प्रबंधन",
    managedailywork: "दैनिक कार्य प्रबंधन",
    selectEquipmentBy:'उपकरण का चयन करें',
    byNumberPlate:'नंबर प्लेट द्वारा',
    byModelMachineNo : 'मॉडल / मशीन नंबर द्वारा',
    selectEquipment:'उपकरण का चयन करें',

    TimeInterval:'समय अंतराल',
    ptoRunning:'पीटीओ',
    idling:'निष्क्रिय',
    running :'सामान्य गति',
    highSpeedRunning:'तीव्र गति',
    selectedDate: 'चयनित तिथि',
    equipment:'उपकरण',
    performanceInfo:'कार्यक्षमता की जानकारी',
    operationalHour:'परिचालन घंटे (H)',
    ptoHour:'पीटीओ घंटे',
    idleHour:'निष्क्रिय घंटे ',
    runningHour:'सामान्य गति घंटे',
    highSpeedHour:'तीव्र गति घंटे',
    workingHistoryYear: 'कार्य इतिहास (वर्ष)',
    workingHistoryMonth: 'कार्य इतिहास (माह)',
    workDone:'काम किया',
    todayDate:"आज की तारीख",
    noHistoryAvailable: 'कोई इतिहास उपलब्ध नहीं है',
    equipmentOperatingInfo:'उपकरण संचालन जानकारी',
    sNo:'क्रमिक संo',
    operatingHour:'काम करने का समय (घंटे)',
    operatingHourChart:'काम करने का समय (घंटे)',
    operatingHourMinutes:'काम करने का समय (घंटे:मिनट)',
    ptoRunningHour:'पीटीओ समय (घंटे)',
    idlingHour:'निष्क्रिय समय (घंटे)',
    runningTime :'सामान्य गति समय(घंटे)',
    highSpeed:'तीव्र गति (घंटे)',
    workDetail:'कार्य का विवरण (लिखें)',
    refuelQty:'ईंधन भरने की मात्रा(लिo)',
    workingWidth:' काम की चौड़ाई(मीo)',
    acreageArea:'एकर क्षेत्र (हैo)',
    member:'सदस्य',
    hydraulicTemp:'हाइड्रोलिक पानी का तापमान (डिग्री सेल्सियस)',
    hydraulicTempAvg: 'हाइड्रोलिक तेल तापमान औसत (डिग्री सेल्सियस)',
    coolingTempAvg:'ठंडा पानी का तापमान औसत (डिग्री सेल्सियस)',
    coolingTemp: 'ठंडा पानी का तापमान (डिग्री सेल्सियस)',
    alarmInfo :'पानी का तापमान अलार्म अवधि (घंटे)',

    viewRouteMapDetail:'विस्तृत रूट मैप देखें',
    operatingHourAndDyanmicInfo:'ऑपरेटिंग घंटे और परिवर्तनशील जानकारी',
    The_Contract_has_been_expired_for_the_selected_machine:'इस मशीन का कॉन्ट्रैक्ट समाप्त हो चुका है।',
    This_Page_cannot_be_displayed_as_contract_has_expired_for_selected_machine_Kindly_contact_your_service_provider:'यह पृष्ठ प्रदर्शित नहीं किया जा सकता क्योंकि चयनित मशीन के लिए कॉन्ट्रैक्ट की समय सीमा समाप्त हो गई है. कृपया अपने सर्विस प्रदाता से संपर्क करें।',

    
    operationalHour:'संचालन के घंटे',

    /** Manage data Services */
    RoleCode: 'भूमिका कोड',
    RoleName: 'भूमिका का नाम',
    DivisionCode: 'डिवीजन कोड',
    DivisionName: 'प्रभाग का नाम',
    Company:'कंपनी',
// Search
    filters :'फ़िल्टर करें',
    all :'सब',
    machineNum :'मशीन नंबर',
    noEquipmentFound :'कोई उपकरण नहीं मिला।',
    searchEquipments:"उपकरणों की खोज करें" ,
    back:'वापस जाएं',

    //location Information
    locationInformation :'स्थान की जानकारी',
    enterNumberPlate :'नंबर प्लेट डालें',
    enterModelMachineNumberPlate:'मॉडल/मशीन संख्या/नंबर प्लेट डालें',
    selectAll :'सबका चयन करें',
    unSelectAll :'सभी को अचयनित करें',
    viewLocation :'स्थान देखें ',
    activeTractor :'सक्रिय',
    offlineTractor:'ऑफ़लाइन',
    activeErrorTractor:'सक्रिय (त्रुटि)',
    offlineErrorTractor:'ऑफ़लाइन (त्रुटि)',

    //alert Seting
    alertSettings: 'चेतावनी सेटिंग',
    testNotification :'अधिसूचना परीक्षण',
    geofencing:'भौगोलिक सीमा',
    machineTow:'मशीन खींच कर ले जाया गया',
    noPositionInformed:'कोई स्थिति सूचित नहीं',
    batteryRemoved:'बैटरी निकाली गई',
    theft:'चोरी हो गई',
    technicalSystemError:'तकनीकी/प्रणाली त्रुटि',
    notifyMe:'मुझे सूचित करें',
    operatingTime:'काम करने का समय',
    modelMachine:'मॉडल / मशीन नंबर',
    selectNumberPlate:'नंबर प्लेट का चयन करें',
    selectModelMachineNum:'मॉडल / मशीन नंबर चुनें',
    startDate : 'आरंभ तिथि',
    endDate : 'अंतिम तिथि',
    endTime : 'समाप्ति समय',
    startTime : 'शुरू समय',

    // toastr message
    opeartionCantPerform :"यह कार्य नहीं किया जा सकता है। बाद में पुन: प्रयास करें।",
    testNotificationSentBoth :'एसएमएस और ईमेल के जरिए टेस्ट नोटिफिकेशन भेजा गया है।',
    testNotificationSentEmail:'ईमेल के जरिए टेस्ट नोटिफिकेशन भेजा गया है।',
    testNotificationSentSms:'एसएमएस के जरिए टेस्ट नोटिफिकेशन भेजा गया है।',
    testNotificationError:'ईमेल या एसएमएस ध्वज की जाँच की जानी चाहिए।',
    eitherCheckboxChecked:'कृपया तकनीकी, चोरी ,मशीन खींचके ले जाना और बैटरी निकलना में से कम से कम एक का चयन करें',
    fillStartTimeHour:'कृपया प्रारंभ समय घंटा भरें।',
    fillStartTimeMinute:'कृपया प्रारंभ समय मिनट भरें।',
    fillEndTimeMinute:'कृपया अंतिम समय मिनट भरें।',
    fillEndTimeHour:'कृपया अंतिम समय घंटा भरें।',
    startEndTimeNotSame:"प्रारंभ और समाप्ति समय समान नहीं हो सकते।",
    startTimeCantLessThanEndTime:" समाप्ति समय प्रारंभ समय से कम नहीं हो सकता",
    alertSettingUpdated:"चेतावनी सेटिंग को अपडेट किया गया है",
    errorOccured:"त्रुटि हुई है।",

    //manage Report
    manageReports: 'रिपोर्ट प्रबंधन',
    workReport: 'काम की रिपोर्ट',
    utilizationReport: 'उपयोग की रिपोर्ट',
    dateRange:'तिथि सीमा',
    from:'से',
    to:'तक',
    daysMonthly: 'दिन / मासिक',
    operatingHours: 'काम करने का वक्त',
    workPlace: 'कार्यस्थल',
    workerDetails: 'कार्यकर्ता विवरण',
    workdate: 'काम की तारीख',
    totalEngHr: 'कुल यन्त्र घंटे',
    totalPtoHr: 'कुल पीटीओ घंटे',
    totalIdlingHr: 'कुल निष्क्रिय घंटे',
    totalRunningHr : 'कुल सामान्य गति घंटे',
    refuelQuantity:'ईंधन भरने की मात्रा',
    totalAcregeArea : 'कुल एकर क्षेत्र',
    downloadCSV:'सीएसवी रिपोर्ट डाउनलोड करें',
    search:'खोज करें',
    pleaseWait:'कृपया प्रतीक्षा करें...',
    tractorDetails:'ट्रैक्टर विवरण',
    equipmentDetails:'उपकरण विवरण',
    typesOfReports:'रिपोर्ट के प्रकार',
    noReportFound:'कोई रिपोर्ट नहीं मिली',
    loading:'खुल रहा है...',
    dataNotAvailable:'डेटा उपलब्ध नहीं है',
   

     // Service HIstory
     equipmentServiceHistory:'उपकरण की सर्विस का इतिहास',
     serviceHistory:'सर्विस का इतिहास',
     nextServiceByDays:'अगली सर्विस में बाकी दिन',
     lastServiceByDate:'पिछली सर्विस की तारीख',
     nextServiceByHour:'अगली सर्विस में बाकी घंटे',
     lastServiceByHour:'पिछली सर्विस की गई (घंटों में)',
     currentHourMeter:'वर्तमान समय मीटर',
     serviceDate:'सर्विस की तारीख',
     serviceDetail:'सर्विस विस्तार',
     otherServiceDetail:'अन्य सर्विस विस्तार',
     registeredBy:'दर्ज करने वाले का नाम',
     hourMeteratService:'सर्विस के वक़्त समय मीटर (घंटे)',
     serviceCompany:'सर्विस कंपनी',
     viewDetail:'विस्तार से देखें',
     noServiceHistory:'कोई सर्विस नहीं की गई है।',
     replace:'प्रतिस्थापित',
     item:'आइटम',
     check:'जांच',
     clean:'साफ़',
     adjust:'ठीक किया',
     view :'देखें',
     rotation:'रोटेशन',
     adjustClean:'ठीक किया(साफ)',
     oilAndGrease:'तेल और ग्रीस',
     supply:'सप्लाई',
     serviceCheckList:'सर्विस चेकलिस्ट',

     // Area Measurement
    areaMeasurement:'क्षेत्र माप',
    searchAddress: "पता खोजों ",
    Search_for_address:"पता खोजें",
    
     // My Member
    myMember:'मेरे सदस्य',
    teamMember:'टीम सदस्य',
    addNewMember:'नया सदस्य जोड़ें',
    editMember:'सदस्य को संपादित करें',
    noMemberFound:'कोई सदस्य नहीं मिला।',
    loadingMember:'सदस्य लोड हो रहे हैं ...',
    memberID:'उपयोगकर्ता आईडी',
    firstName:'पहला नाम',
    lastName:'उपनाम',
    mobileNumber:'मोबाइल नंबर',
    alternateMobileNumber:'वैकल्पिक मोबाइल नंबर',
    emailAddress:'ईमेल पता',
    streetAddress:'गली का पता',
    city:'शहर',
    pin:'पिन',
    selectState:'राज्य चुनें',
    country:'देश',
    add:'जोड़ें',
    memberPhoto:'सदस्य फोटो',
    india:'भारत',
    update:'अपडेट करें',
    deleteMember:'सदस्य हटाएँ',
    resetPassword:'रीसेट पासवर्ड',
    edit:'संपादित करें',
    state:'राज्य',


    encounteredProblem:"हमने कुछ समस्या का सामना किया है। बाद में पुन: प्रयास करें।",
    newMemberAddedSuccess:"नया सदस्य सफलतापूर्वक जोड़ा गया है।",
    updateSuccess:"की प्रोफ़ाइल को सफलतापूर्वक अपडेट कर दिया गया है।",
    memberIdTaken:' यह उपयोगकर्ता आईडी पहले ही ले चुका है।',
    resetConfirmation:'क्या आप वास्तव में ',
    resetConfirmation2:" का पासवर्ड रीसेट करना चाहते हैं?",
    hasRemoved:" को हटा दिया गया है।",
    couldNotComplete:"यह अनुरोध पूरा नहीं किया जा सका।बाद में फिर से कोशिश करें।",
    deleteMemberConfirmation: "क्या आप वास्तव में इस सदस्य को हटाना चाहते हैं ?",

    //validation
    invalidEmail:"अमान्य ईमेल पता!",
    passwordNotMatch:"पासवर्ड मेल नहीं खा रहे हैं!",
    enterOnlyNumber:"केवल नंबर दर्ज करें!",
    enterNumberOrAlphabets:"केवल संख्या या अक्षर दर्ज करें!" ,
    dateNotGreaterThanCurrentDate:"तिथि वर्तमान तिथि से अधिक नहीं होनी चाहिए!" ,
    mustBe:"",
    characters:" अक्षर होने चाहिए!",
    requiredField:'यह फ़ील्ड आवश्यक है!',
    spacesNotAllowed:'मान के रूप में रिक्त स्थान की अनुमति नहीं है!' ,
    enterOnlyFiveDigitNumber :'कृपया 6 अंकों से कम संख्या दर्ज करें',

        // Header

    profile:'प्रोफाइल',
    changePassword:'पासवर्ड बदलें',
    logout:'लॉग आउट',
    systemMaintenanceAlerts:'सिस्टम रखरखाव चेतावनी',
    noDataFound:'कोई डेटा नहीं मिला',
    recentAlerts:'नई चेतावनी - त्रुटि / चोरी',
    occuredInLast72Hour:'पिछले 72 घंटों में हुआ',
    serviceDueAlerts:'लंबित सर्विस चेतावनी',
    sessionOutLogout: 'लंबे समय तक कोई गतिविधि न होने के कारण आप लॉग आउट हो गए हैं। जारी रखने के लिए "ओके" पर क्लिक करें',
    welcomeYanmar: 'यानमार स्मार्टअसिस्ट में आपका स्वागत है',

    //profile
    myAccount:'मेरा खाता',
    myProfileDetail:'मेरा प्रोफ़ाइल विवरण',
    fullName:'पूरा नाम',
    displayLanguage:'प्रदर्शित भाषा',
    timeZone:'समय क्षेत्र',
    unitDisplay:'इकाई प्रदर्शन',
    volume:'मात्रा',
    middleName:'मध्य नाम',
    selectLanguage:'भाषा चुनें',
    selectTimezone:'समय क्षेत्र चुनें',
    selectAtLeastOne:'कृपया कम से कम एक विकल्प चुनें।',
    displayKmVolumeL:'दूरी: किमी / मात्रा: लीटर',
    displayMileVolumeGallon:'दूरी: मील / मात्रा: गैलन',
    sms:'एसएमएस',
    email:'ईमेल',
    profileUpdated:'आपका प्रोफाइल अपडेट किया गया है। ',
    
    //Change Password
    oldPassword:'पुराना पासवर्ड',
    newPassword:'नया पासवर्ड',
    confirmPassword:'पासवर्ड की पुष्टि कीजिये',
    changePassword:'पासवर्ड बदलें',
    passwordChangeSuccess:"पासवर्ड सफलतापूर्वक बदल दिया गया है।",
    passwordChangeError:"यह अनुरोध पूरा नहीं किया जा सका। बाद में पुन: प्रयास।",

    //Reset Password
    passwordCanNotEmpty:"पासवर्ड खाली स्थान नहीं हो सकता",
    passwordShouldHave6AlphaNumeric:"नए पासवर्ड में कम से कम 6 वर्णमाला या संख्या होने चाहिए",
    memberIdCanNotEmpty:"उपयोगकर्ता आईडी खाली नहीं हो सकती।",
    confirmPasswordCanNotEmpty:"पुष्टि पासवर्ड स्थान खाली नहीं हो सकता",
    confirmPasswordDoesNotMatch:"पुष्टि पासवर्ड नए पासवॉड के साथ मेल नहीं खाती है",
    resetPassword:"पासवर्ड रीसेट",
    backToLogin:'लॉगिन पर वापस जाएं',
    clickOnResetPassword:'अपना पासवर्ड रीसेट करने के लिए रीसेट पासवर्ड बटन पर क्लिक करें। नया पासवर्ड आपके पंजीकृत मोबाइल नंबर पर भेजा जाएगा। ',
    passwordSentOnMobileNumber:"हमने आपके पंजीकृत मोबाइल नंबर पर एक नया पासवर्ड भेजा है।  ",
    doYouReallyWantToReset:"क्या आप वास्तव में अपना पासवर्ड रीसेट करना चाहते हैं? ",


    //forget password
    enterUserNameAndMobileNumber:"अपना उपयोगकर्ता की आईडी और मोबाइल नंबर / ईमेल पता दर्ज करें। हम आपको पासवर्ड रीसेट करने के लिए लिंक भेजेंगे।",
    alreadyHavePassword:"पासवर्ड पहले से ही है?",
    signIn:"साइन इन करें",

    //menu
    MY_EQUIPMENTS:"मेरे उपकरण",
    MANAGE_DAILY_WORK:"दैनिक कार्य प्रबंधन",
    LOCATION_INFORMATION:"स्थान सूचना",
    ALERT_SETTINGS:"चेतावनी सेटिंग",
    MANAGE_REPORTS:"रिपोर्ट प्रबंधन",
    EQUIPMENT_SERVICE_HISTORY:"सर्विस का इतिहास",
    AREA_MEASUREMENT:"क्षेत्र माप",
    MY_MEMBERS:"मेरे सदस्य",
    TEAM_MEMBERS:"टीम सदस्य" ,
    MEMBER_MANAGEMENT:"सदस्य प्रबंधन",
    MY_ACCOUNT:"मेरा खाता",
  

       //member Mangement
       memberManagement:"सदस्य प्रबंधन",
       memberName:"सदस्य का नाम",
       selectMember:"सदस्य का चयन करें",
       newPermissionAddedSuccess:"अनुमतियां सफलतापूर्वक अपडेट कर दी गई हैं।",
       permissions:"अनुमतियां",

       distance:"दूरी",
       yes:"हाँ",
       close:"बंद करे"
}