import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import "./style.scss";
import Moment from 'react-moment';
import { getRegisteredMaps } from "../../../api/admin/custom-map";
import { setLoader } from "../../../actions/Application";
/** importing sub-components */
import Pagination from "../../shared/Pagination";
/** importnig constants */
import { LIMIT } from "../../../constants/Constants";

class RegisteredMapComponent extends Component{
    constructor(props){
        super(props);

        this.state = {
            maps: [],
            activePage: 1,
            total: 0,
        }
    }

    componentWillMount(){
        this.getRegisteredMapData({page:1});   
    }

    getRegisteredMapData = (req) => {
        this.props.setLoader({ loader: {open:true}});

        getRegisteredMaps(req)
        .then(result => {
            this.props.setLoader({ loader: {open:false}});

            this.setState({
                maps: (result.data && result.data.hsitoryMapListObj) || [],
                activePage: this.state.activePage || 1,
                total: (result.data.countResult && result.data.countResult.totalCounts) ? result.data.countResult.totalCounts : this.state.total 
            });
        })
        .catch(err => this.props.setLoader({ loader: {open:false}}));
    }

    onPageChange = (page) => {
        this.setState({ activePage: page || 1 }, () => {
            this.getRegisteredMapData({ page });
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.initializeTab != this.props.initializeTab) {
            this.onPageChange(1);
        }
    }


    render(){
        let { maps, activePage, total } = this.state;
        return(
            <Fragment>
                {maps && <table className="tabledashboard table table-striped table-advance table-hover m-top-30">
                    <thead>
                        <tr>
                            <th>Model</th>
                            <th>Machine Number</th>
                            <th>Map1</th>
                            <th>Map2</th>
                            <th>Map Type</th>
                            <th>Sent Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {maps.length>0 && maps.map((item, i) => (
                            <tr key={item.model+i}>
                                <td>{item.model || "NA"}</td>
                                <td>{item.machineNum || "NA"}</td>
                                <td>{item.map1 || "NA"}</td>
                                <td>{item.map2 || "NA"}</td>
                                <td>{item.mapType || "NA"}</td>
                                <td><Moment format="DD MMM, YYYY hh:mm A">{item.mapSentDate}</Moment></td>
                            </tr>
                        ))}

                        {!maps.length && <tr><td align="center" colSpan="6">No Data Found</td></tr>}
                        
                    </tbody>
                </table>}

                <Pagination 
                    limit={LIMIT}
                    total={total}
                    activePage={activePage}
                    onChange={this.onPageChange}
                    data={maps}
                />
            </Fragment>
        );
    }
}

export default connect(null, {setLoader})(RegisteredMapComponent);