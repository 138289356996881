import React, { Component, Fragment } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from 'classnames';
import { FaTable, FaCloudUploadAlt } from "react-icons/fa";
import AddFotaComponent from "./AddFotaComponent";
import BrowseFotaComponent from "./BrowseFotaComponent";
import BulkFotaComponent from "./BulkFotaComponent";
import { setLoader, setToastr } from "../../../actions/Application";
import {connect} from "react-redux";
import "./style.scss";

class FotaComponent extends Component{

    constructor(props){
        super(props);
 
        this.state = {
            activeTab: 2,
            ack: false,
            initializeTabOne: true,
            initializeTabTwo: true,
        }
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
        if (tab === 1) {
            let { initializeTabOne } = this.state;
            this.setState({ initializeTabOne: !initializeTabOne });
        } else if (tab === 2) {
            let { initializeTabTwo } = this.state;
            this.setState({initializeTabTwo: !initializeTabTwo });
        }
    }

    getAckWhenAdded = bool => this.setState({ack: bool}, () => this.setState({ack: false}));

    render(){
        return(
            <Fragment>
                <section className='reports repo'>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === 2 }) }
                            onClick={() => { this.toggle(2); } }
                            >
                            <div>
                                <FaCloudUploadAlt />
                                <div className="tabheading">Save FOTA</div>
                            </div>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === 1 }) }
                            onClick={() => { this.toggle(1); } }
                            >
                            <div>
                                <FaTable />
                                <div className="tabheading">Equipment List</div>
                            </div>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === 3 }) }
                            onClick={() => { this.toggle(3); } }
                            >
                            <div>
                                <FaCloudUploadAlt />
                                <div className="tabheading">Bulk Save</div>
                            </div>
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId={1}>
                        <AddFotaComponent getAck={this.getAckWhenAdded} initializeTab={this.state.initializeTabOne}/>
                    </TabPane>
                    
                    <TabPane tabId={3}>
                        <BulkFotaComponent ack={this.state.ack} />
                    </TabPane>

                    <TabPane tabId={2}>
                        <BrowseFotaComponent initializeTab={this.state.initializeTabTwo}/>
                    </TabPane>
                </TabContent>
                
                </section>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    setLoader, setToastr
}

export default connect(null, mapDispatchToProps)(FotaComponent);