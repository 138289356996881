export default {
    Dashboard:'Dashboard',
    myEquipment:'My Equipments',
    username: 'Username',
    password: 'Password',
    userID:'User ID',
    rememberIdPassword: 'Remember User ID & Password',
    login: 'LOGIN',
    forgotPassword: 'Forgot your password?',
    latestEquipment: 'Latest Equipments',
    pleaseEnterYourLoginID:'Please Enter Your User ID',
    pleaseEnterYourLoginpassword:'Please Enter Your Password',

    //My Equipment
    numberPlate: 'Number Plate',
    type: 'Type',
    model: 'Model',
    machineno: 'Machine No.',
    machineSaleId: 'Machine SaleId',
    fuel: 'Fuel',
    tractor:'Tractor',
    contractDate:'Contract Date',
    contractExpiry:'Contract Expiry',
 
    language : 'Language',
    title : 'Title',
    alert: 'Alert',
    location: 'Location',
    error: 'Error',
    hourMeter: 'Hourmeter(Hrs)',
    updatedOn: 'Updated On',
    occurredon : 'Occurred On',
    stateCountry: 'State/Country',
    emergencyContact : 'Emergency Contact',
    emergencyContactNumber : 'Emergency Contact Number',
    address: 'Address',
    hourmeter: 'Hourmeter(Hrs)',
    currentLocation: 'Location',
    equipmentType: 'Equipment Type',
    editPicture: 'Edit Picture',
    save: 'Save',
    cancel: 'Cancel',
    on:'ON',
    off:'OFF',
   
    editSettings: 'Edit Settings',
    geoFencing: 'Geofencing',
    status: 'Status',
    OperatingTime: 'Operating Time',
    on: 'on',
    off: 'off',
    time: 'Time',

    oilingQuantity: 'Oiling Quantity',
    averageMileage: 'Average Mileage',
    fuelConsumption: 'Fuel Consumption (Ltr)',
    averageSpeed: 'Average Speed (Km/h)',
    memo: 'Memo',
    
    alertdate : 'Alert Date',
    reportDate: 'Report Date',
    urgency : 'Urgency',
    owner : 'Owner',
    emergnecyConatct : 'Emergency Contact',
    application : 'Application',
    messageTitle : 'Message Title',
    startOn : 'Start On',
    endsOn : 'Ends On',
    description : 'Description',
    totalDistance: 'Total Distance Travelled',
    averagePmAmount : 'Average PM Amount',
    averageDiffPmAmount : 'Average Diff. PM Amount',
    avDiffIntTemp : 'Average Diff. Intermediate Temp.',
    averageEngLdFac : 'Average Engine Load Factor',
    avDiffAshAmt : 'Average Diff Ash Amount',
    avCoolantTemp : 'Average Coolant Temp.',
    avActEnbSpeed : 'Average Actual Engine Speed',
    avAshAmount : 'Average Ash Amount',
    first : 'First',
    second : 'Second',
    third : 'Third',
    supportresults : 'Support Comments',
    supportComments : 'Support Comments',
    responders : 'Responders',
    dataTime : 'Date/Time',
    
    /** Daily Work Words */
    manageDailyWork: "Manage Daily Work",
    selectEquipment:'Select Equipment',
    byNumberPlate:'Number Plate',
    byModelMachineNo : 'Model/ Machine No.',
    selectEquipment:'Select Equipment',
    The_Contract_has_been_expired_for_the_selected_machine:"The Contract has been expired for the selected machine.",


    TimeInterval:'Time Interval',
    ptoRunning:'PTO Running',
    idling:'Idling',
    running :'Running',
    highSpeedRunning:'High Speed Running',

    equipment:'Equipment',
    performanceInfo:'Performance Info',
    operationalHour:'Operational Hours (Hrs)',
    ptoHour:'PTO Hr (Hrs)',
    idleHour:'Idle Hr (Hrs)',
    runningHour:'Running Hr (Hrs)',
    highSpeedHour:'High Speed Running(Hrs)',
    updatedOn: 'Updated On',
    selectedDate: 'Selected Date',
    workingHistoryYear: 'Working History(Yr)',
    workingHistoryMonth: 'Working History(Mo)',
    workDone:'Work Done',
    todayDate:"Today's Date",
    noHistoryAvailable: 'No History Available',
    equipmentOperatingInfo:'Equipment Operating Info',
    sNo:'S.No',
    operatingHour:'Operating Hours (HH:MM:SS)',
    operatingHourChart:'Operating Hours (Hrs)',
    operatingHourMinutes:'Operating Hours(HH:MM)',
    ptoRunningHour:'PTO Time (Hrs)',
    idlingHour:'Idling Time (Hrs)',
    runningTime :'Running Time (Hrs)',
    highSpeed:'High Speed (Hrs)',
    workDetail:'Work Details (Memo)',
    refuelQty:'Refuel Qty(L)',
    workingWidth:'Working Width(m)',
    acreageArea:'Acreage Area (Ha)',
    member:'Member',
    hydraulicTemp:'Hydraulic Oil Temperature (°C)',
    hydraulicTempAvg: 'Hydraulic Oil Temperature Avg (°C)',
    coolingTemp: 'Cooling Water Temperature (°C)',
    coolingTempAvg:'Cooling Water Temperature  Avg(°C)',
    alarmInfo : 'Water Temperature Alarm Duration (Hrs)',

    viewRouteMapDetail:'View Route Map Details',
    operatingHourAndDyanmicInfo:'Operating Hours & Dynamic Info',

    
    operationalHour:'Operational Hours',
    
    
    /** Manage data Services */
    RoleCode: 'Role Code',
    RoleName: 'Role Name',
    DivisionCode: 'Division Code',
    DivisionName: 'Division Name',
    Company:'Company',

    // Equipment Detail
    tractorDetails :'Tractor Details',
    equipmentDetails: 'Equipment Details',
    view :'View',


    //Search
    filters :'Filters',
    all :'All',
    machineNum :'Machine Num',
    noEquipmentFound :'No equipment found.',
    searchEquipments:"Search Equipments" ,
    back:"Back",

    //location Information
    locationInformation :'Location Information',
    enterNumberPlate :'Enter Number Plate',
    enterModelMachineNumberPlate :'Enter Model/Machine Number/Number Plate',
    selectAll :'SELECT ALL',
    unSelectAll :'UNSELECT ALL',
    viewLocation :'VIEW LOCATION',
    activeTractor :'Active',
    offlineTractor:'Offline',
    activeErrorTractor:'Active(Error)',
    offlineErrorTractor:'Offline(Error)',

    //Alert Setting
    alertSettings: 'Alert Settings',
    testNotification :'Test Notification',
    geofencing:'GEOFENCING',
    machineTow:'Machine Tow',
    noPositionInformation:'No Position Information',
    batteryRemoved:'Battery Removed',
    theft:'Theft',
    technicalSystemError:'Technical/System Error',
    notifyMe:'NOTIFY ME',
    operatingTime:'OPERATING TIME',
    modelMachine:'Model/Machine Number',
    selectNumberPlate:'Select Number Plate',
    selectModelMachineNum:'Select Model/Machine Number',
    startDate : 'Start Date',
    endDate : 'End Date',
    endTime : 'End Time',
    startTime : 'Start Time',
    This_Page_cannot_be_displayed_as_contract_has_expired_for_selected_machine_Kindly_contact_your_service_provider:'This Page cannot be displayed as contract has expired for selected machine.Kindly contact your service provider.',

    //Area Measurement
    areaMeasurement: 'Area Measurement',
    calculateArea: 'CALCULATED AREA',
    searchAddress: 'SEARCH ADDRESS',
    hectare: 'Hectare',
    sqmt: 'Sq Meter',
    searchArea: 'Search',
    areaText: 'Draw the area for area measurement',
    saveArea: 'Save',
    Search_for_address:'Search for address',

    // toastr message
    opeartionCantPerform :"This operation can't be performed. Please try again later.",
    testNotificationSentBoth :'Test Notification has been sent via SMS and Email.',
    testNotificationSentEmail:'Test Notification has been sent via Email.',
    testNotificationSentSms:'Test Notification has been sent via SMS.',
    testNotificationError:'Either Email or SMS flag should be checked.',
    eitherCheckboxChecked:'Please select at least one from Technical error, Theft, Battery removal and Machine Towed.',
    fillStartTimeHour:'Please fill start time hour.',
    fillStartTimeMinute:'Please fill start time minute.',
    fillEndTimeMinute:'Please fill end time minute.',
    fillEndTimeHour:'Please fill end time hour',
    startEndTimeNotSame:"Start and End time can't be same.",
    startTimeCantLessThanEndTime:"End time cannnot be less than start time",
    alertSettingUpdated:"Alert settings has been updated.",
    areaUpdated:"Area has been updated.",
    errorOccured:"Error Occured",

    //Manage Report
    manageReports: 'Manage Reports',
    workReport: 'Work Report',
    utilizationReport: 'Utilization Report',
    dateRange:'Date Range',
    from: 'From',
    to:'To',
    daysMonthly: 'days/monthly',
    operatingHours: 'Operating Hours',
    workPlace: 'Work Place',
    workerDetails: 'Worker Details',
    workdate: 'Work Date',
    totalEngHr: 'Total Eng Hr',
    totalPtoHr: 'Total PTO Hr',
    totalIdlingHr: 'Total Idling Hr',
    totalRunningHr : 'Total Running Hr',
    totalAcregeArea : 'Total Acrege Area',
    refuelQuantity:'Refuel Quantity',
    downloadCSV:'Download CSV Report',
    search:'Search',
    pleaseWait:'Please Wait...',
    typesOfReports:'Types of reports',
    noReportFound:'No Report Found',
    loading:'Loading...',
    dataNotAvailable:'Data not available',

    // Service HIstory
    equipmentServiceHistory:'Equipment Service History',
    serviceHistory:'Service History',
    nextServiceByDays:'Next Service Due In Days',
    lastServiceByDate:'Last Serviced Date',
    nextServiceByHour:'Next Service  Due In Hours',
    lastServiceByHour:'Last Serviced Hours',
    currentHourMeter:'Current Hourmeter',
    serviceDate:'Service Date',
    serviceDetail:'Service Detail',
    otherServiceDetail:'Other Service Detail',
    registeredBy:'Registered By',
    hourMeteratService:'Hourmeter at Service (Hrs)',
    serviceCompany:'Service Company',
    viewDetail:'View Detail',
    noServiceHistory:'No Service history Found',
    replace:'Replace',
    item:'Item',
    check:'Check',
    clean:'Clean',
    adjust:'Adjust',
    rotation:'Rotation',
    adjustClean:'Adjust(Clean)',
    oilAndGrease:'Oil and Grease',
    supply:'Supply',
    serviceCheckList:'Service Checklist',

    // Area Measurement
    areaMeasurement:'Area Measurement',
    // My Member
    myMember:'My Members',
    teamMember:'TEAM MEMBERS',
    addNewMember:'Add New Member',
    editMember:'Edit Member',
    addNewMember:'Add New Member',
    noMemberFound:'No Member Found.',
    loadingMember:'Loading Members...',
    memberID:'Member ID',
    firstName:'First Name',
    lastName:'Last Name',
    mobileNumber:'Mobile Number',
    alternateMobileNumber:'Alternate Mobile Number',
    emailAddress:'Email Address',
    streetAddress:'Street Address',
    city:'City',
    pin:'Pin',
    selectState:'Select State',
    country:'Country',
    add:'Add',
    memberPhoto:'Member Photo',
    india:'India',
    update:'Update',
    deleteMember:'Delete Member',
    resetPassword:'Reset Password',
    edit:'Edit',
    state:'State',


    encounteredProblem:"We've encountered some issue. Please try again later.",
    newMemberAddedSuccess:"New member has been added successfully.",
    updateSuccess:"'s profile has been updated successfully.",
    memberIdTaken:'This user ID is taken.',
    resetConfirmation:'Do you really want to reset ',
    resetConfirmation2:"'s password?",
    hasRemoved:" has been removed.",
    couldNotComplete:"This request couldn't be completed. Try again later.",
    deleteMemberConfirmation: "Do you really want to remove this member ?",


    //validation
    invalidEmail:"Invalid email address",
    passwordNotMatch:"Passwords don\'t match",
    enterOnlyNumber:"Enter numbers only",
    enterNumberOrAlphabets:"Enter numbers or alphabets only" ,
    dateNotGreaterThanCurrentDate:"Date should not be greater than current date" ,
    mustBe:"Must be ",
    characters:" characters",
    requiredField:'This field is required!',
    spacesNotAllowed:'Spaces are not allowed as value!', 
    enterOnlyFiveDigitNumber : 'Please enter number less then 6 digit',
    
    // Header
    profile:'Profile',
    changePassword:'Change Password',
    logout:'Logout',
    systemMaintenanceAlerts:'System Maintenance Alerts',
    noDataFound:'No Data Found',
    recentAlerts:'Recent Alerts - Error/Theft',
    occuredInLast72Hour:'Occurred in last 72 Hours',
    serviceDueAlerts:'Service Due Alerts',
    sessionOutLogout: 'You have been logged out due to inactivity. Please click OK to continue.',
    welcomeYanmar: 'Welcome to YANMAR SMARTASSIST.',

    //profile
    myAccount:'My Account',
    myProfileDetail:'My Profile Details',
    fullName:'Full Name',
    displayLanguage:'Display Language',
    timeZone:'Time Zone',
    unitDisplay:'Unit Display',
    volume:'Volume',
    middleName:'Middle Name',
    selectLanguage:'Select Language',
    selectTimezone:'Select Timezone',
    selectAtLeastOne:'Please select at least one option.',
    displayKmVolumeL:'Distance:Km/Volume:L',
    displayMileVolumeGallon:'Distance:Mile/Volume:Gallon',
    sms:'SMS',
    email:'Email',
    profileUpdated:'Your profile has been updated.',

    //Change Password
    oldPassword:'Old Password',
    newPassword:'New Password',
    confirmPassword:'Confirm Password',
    changePassword:'Change Password',
    passwordChangeSuccess:"Password has been changed successfully.",
    passwordChangeError:"This request couldn't be completed. Try again later.",

    //Reset Password
    passwordCanNotEmpty:"Password can't be empty",
    passwordShouldHave6AlphaNumeric:"New Password should have at least 6 alphanumeric characters",
    memberIdCanNotEmpty:"User ID can't be empty.",
    confirmPasswordCanNotEmpty:"Confirm Password can't be empty",
    confirmPasswordDoesNotMatch:"Confirm Password doesn't match",
    resetPassword:"Reset Password",
    backToLogin:'Back to Login',
    clickOnResetPassword:' Click on Reset Password button to reset your password. New Password will be sent to your registered mobile number.',
    passwordSentOnMobileNumber:"We've sent a new password on your registered mobile number. ",
    doYouReallyWantToReset:"Do you really want to reset your password? ",

    //forget password
    enterUserNameAndMobileNumber:"Enter your User ID and Mobile Number / Email Address. We'll send you the link to reset password.",
    alreadyHavePassword:"Already have your password?",
    signIn:"Sign In",
    // menu
    MY_EQUIPMENTS:"MY EQUIPMENTS",
    MANAGE_DAILY_WORK:"MANAGE DAILY WORK",
    LOCATION_INFORMATION:"LOCATION INFORMATION",
    ALERT_SETTINGS:"ALERT SETTINGS",
    MANAGE_REPORTS:"MANAGE REPORTS",
    EQUIPMENT_SERVICE_HISTORY:"EQUIPMENT SERVICE HISTORY",
    MY_MEMBERS:"MY MEMBERS",
    TEAM_MEMBERS:"TEAM MEMBERS",
    MEMBER_MANAGEMENT:"MEMBER MANAGEMENT",
    MY_ACCOUNT:"MY ACCOUNT",
    AREA_MEASUREMENT: 'AREA MEASUREMENT',

    //member Mangement
    memberManagement:"Member Management",
    memberName:"Member Name",
    selectMember:"Select Member",
    newPermissionAddedSuccess:"Permissions have been updated successfully.",
    permissions:"Permissions",

    distance:"Distance",
    yes:"Yes",
    close:"Close",

    //equipment details service provider
    renew: "Renew"

}

