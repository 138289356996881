import React, { Component, Fragment } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import {FaSort, FaSortUp, FaSortDown} from 'react-icons/fa'
import { LIMIT } from "../../../constants/Constants";
import Pagination from "../../shared/Pagination";
import { withRouter } from "react-router-dom";
import Moment from "react-moment";
import "./style.scss"

class ContractManagementDetailComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activePage: 1,
            total: 0,
            searchField: '',
            key:null,
            click: true,
        }
        this.sortBy = this.sortBy.bind(this)
    }
    handlePageChange(page,sortingParam) {
        this.setState({key:''})
        let { searchFields } = this.props;
        this.setState({ activePage: page || 1 }, () => {
            this.props.getEquipmentContractList(page,searchFields,sortingParam);
        });
    }
    componentWillReceiveProps(nextProps){
        if(this.props.searchFields!=nextProps.searchFields){
            this.setState({activePage:1})
        }
    }
    sortBy(key) {
        let page = this.state.activePage;
        let sortingParams =`eventField=${key}&order=${((this.state.click === true) ? 'ASC' : 'DESC')}`
        this.handlePageChange(page, sortingParams);
        this.setState({
            key: key,
            click: !this.state.click,
            sortingParams: sortingParams
        });
    }
    render() {
        let { data } = this.props;
        let { activePage } = this.state;
        let {push} = this.props.history;
        let sortType = (!this.state.click) ? <FaSortUp/> : <FaSortDown/>
   
        return (
            <Fragment>
                <table className="tabledashboard table table-striped table-advance table-hover m-top-30">

                    {data != undefined &&
                        <thead>
                            <tr>
                                {/* <th>Sr. No</th> */}
                                <th  onClick={() => this.sortBy('status')}>Status {(this.state.key === 'status') ? sortType : <FaSort/>}</th>
                                <th  onClick={() => this.sortBy('machineNum')}>Machine No. {(this.state.key === 'machineNum') ? sortType : <FaSort/>}</th>
                                <th  onClick={() => this.sortBy('model')}>Model  {(this.state.key === 'model') ? sortType : <FaSort/>}</th>
                                <th  onClick={() => this.sortBy('ownerName')}>Owner  {(this.state.key === 'ownerName') ? sortType : <FaSort/>}</th>
                                <th  onClick={() => this.sortBy('serviceCompany')}> Service Company  {(this.state.key === 'serviceCompany') ? sortType : <FaSort/>}</th>
                                <th  onClick={() => this.sortBy('contractNumber')}>Contract No.  {(this.state.key === 'contractNumber') ? sortType : <FaSort/>}</th>
                                <th style={{width:'304px'}}>Contract Start Date</th>
                                <th style={{width:'300px'}}> Contract End Date</th>
                                <th>Remarks</th>
                            </tr>
                        </thead>}
                    {data != undefined &&
                        <tbody>
                        {(data.getContractList).length > 0 && data.getContractList.map((data, i) => <Tr push={push} data={data} key={i} />)}

                        {!(data.getContractList).length && <tr><td align="center" colSpan="12">No Contract Details Found</td></tr>}
                        </tbody>
                        }
                </table>
                <Pagination
                    limit={LIMIT}
                    total={data && data.countResult.totalCounts}
                    activePage={activePage}
                    onChange={this.handlePageChange.bind(this)}
                    data={data != undefined && data.getContractList || []}
                />
            </Fragment>
        );
    }
}

//const Tr = ({ data, push }) => <tr onClick={() =>push(`/service/contract-renew/${data.machineSaleId}`)}>
const Tr = ({ data,push}) => <tr onClick={() =>push(`/service/edit-owner-details/${data.id}/${data.machineSaleId}`)}>
    
    <td>{data.status}</td>
    <td>{data.machineNum}</td>
    <td>{data.model}</td>
    <td>{data.owner}</td>
    {data.companyName && data.companyName.length >= 10 ?
        <td><div class="tooltip1">{data.companyName.substring(0, 10)}...<span class="tooltiptext">{data.companyName}</span></div></td>
        : <td>{data.companyName}</td>
    }
    {data.contractNumber && data.contractNumber.length >= 5 ?
        <td><div class="tooltip1">{data.contractNumber.substring(0, 5)}...<span class="tooltiptext">{data.contractNumber}</span></div></td>
        : <td>{data.contractNumber}</td>
    }
    <td>{data.contractDate ? (<Moment format="DD MMM YYYY" >
        {data.contractDate}
    </Moment>) : " "}</td>
    <td>{data.contractPeriod ? (<Moment format="DD MMM YYYY" >
        {data.contractPeriod}
    </Moment>) : " "}</td>
    {data.remark && data.remark.length >= 10 ?
        <td><div class="tooltip1">{data.remark.substring(0, 10)}...<span class="tooltiptext">{data.remark}</span></div></td>
        : <td>{data.remark}</td>
    }
</tr>

export default (withRouter(ContractManagementDetailComponent));