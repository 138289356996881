import { takeLatest, call, put } from 'redux-saga/effects';
import api from '../../api/index';
import { SET_LOGGED_IN_TOKEN, GET_USER_LOGGED_IN, SENDING_REQUEST, INVALID_LOGIN, SET_ALERT_NOTIFICATION_DATA, USER_FETCH_DATA_FAIL } from '../../types';
import CryptoJS from 'crypto-js';
import { hashKey } from '../../constants/Constants';
import cookie from 'react-cookies';

/**
 * Call the api and dispatch data to the reducer
 * @param { Object } action Contained object data to be send to API call
 */
export function* loginUser(action) {
  
  const data = action.data;
  try {
    yield put(
      {
        type: SENDING_REQUEST,
        customerData: '',
        resMsg: '',
        isSending: true,
        isLoggedIn: false,
        error: false
      }
    )
    const response = yield call(api.user.login, data);
    if(response.statusCode === 200){
      //if (response.data.length > 150) {
      if (response.data.token) {
        let params = JSON.stringify({ 'token': response.data.token }), profile = response.data.user,functionList=response.data.functions;
        localStorage.setItem('TOKEN', params);
        localStorage.setItem('LOGINID', profile.id);
        localStorage.setItem('FUNCTION_LIST',CryptoJS.AES.encrypt(JSON.stringify(functionList), hashKey));
        let date = new Date();
        date.setTime(date.getTime() + (30 * 60 * 1000));
        cookie.save('user_activity', 1, { path: '/', expires: date });
        localStorage.setItem('authenticated', CryptoJS.AES.encrypt('Authenticated_user', hashKey));
        /**
        * Get Loggedin user profile details
        */
        /* const profile = yield call(api.user.getProfile,{});
        if(profile.statusCode === 200){
          localStorage.setItem('user_data', CryptoJS.AES.encrypt(JSON.stringify(profile), hashKey))
        } */
        localStorage.setItem('user_data', CryptoJS.AES.encrypt(JSON.stringify(profile), hashKey))

        // needed
        /* const sysResponse = yield call(api.alertNotify.getSysMentainInfo,{ data: { module: "SMARTASSIST CUSTOMER"}});
        const aresponse = yield call(api.alertNotify.getAlerts, {});
        const sysAlertResponse = yield call(api.alertNotify.getScheduleSysAlert, {});
        
        yield put({ type: SET_ALERT_NOTIFICATION_DATA, data:[aresponse.data, sysAlertResponse.data, sysResponse.data], resMsg: aresponse.message, isSending: false, error: false }) */


        yield put(
          {
            type: SET_LOGGED_IN_TOKEN,
            token: response,
            resMsg: '',
            isSending: false,
            isLoggedIn: true,
            error: false,
          }
        );
      }else{
        yield put(
          {
            type: INVALID_LOGIN,
            customerData: '',
            resMsg: response,
            isSending: false,
            isLoggedIn: false,
            error: true,
          }
        );
      }
    }else {
      yield put(
        {
          type: INVALID_LOGIN,
          customerData: '',
          resMsg: response,
          isSending: false,
          isLoggedIn: false,
          error: true,
        }
      );
    }

    /*switch (response.status) {
      case 200 :
        
        break;
      default:
            
        break;
    }*/


  } catch (e) {
    yield put({
      type: USER_FETCH_DATA_FAIL,
      payload: {
        error: e.message, isLoggedIn: false, errorCheck: true, resMsg: e.message, isSending: false
      }
    })
  }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchLoginUser() {
  yield takeLatest(GET_USER_LOGGED_IN, loginUser)
}
