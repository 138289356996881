import React, { Component, Fragment } from "react";
import { Field, reduxForm, reset ,change} from "redux-form";
import {connect} from "react-redux";
import Moment from 'react-moment';
import ReactDOM from "react-dom";
import { required, onlyNumbers } from "../../shared/Validation";
import { renderTextField  } from "../../shared/renderField";
import { Row, Col,Card, CardBody  } from "reactstrap";
import {saveMap} from "../../../api/admin/custom-map";
import { setLoader, setToastr, setConfirmBox } from "../../../actions/Application";
import {getCustomAddedMap, deleteMap, updateMap} from "../../../api/admin/custom-map";
import {FaPencilAlt, FaTrashAlt} from 'react-icons/fa'
import Pagination from "../../shared/Pagination";
/** importnig constants */
import { LIMIT } from "../../../constants/Constants";
import SearchFormComponent from "./SearchFormComponent";
import ItemSelection from "./ItemSelection";
let form = "AddCustomMapForm";

class AddCustomMapComponent extends Component{

    constructor(props){
        super(props);

        this.state = {
            maps: null,
            activePage: 1,
            total: 0,
            edit: null,
            qs: {},
            searchItems: [],
            keyword: "",
            fieldType: ""
        }
        this.getEquipmentList=this.getEquipmentList.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }
    getEquipmentList(e){
        let fieldType = e.target.id
        let { value } = ReactDOM.findDOMNode(document.getElementById(e.target.id)), { mapEquipmentList,newCustomMapEqList } = this.props, regex = new RegExp(value.trim(), 'i');
        let filtered = [],models=[], machines=[];
        if (fieldType && fieldType.includes('add')) {
            filtered = newCustomMapEqList.filter(e => {
                if (!models.includes(e.model.toString()) && fieldType === 'addmodel') {
                    if (regex.test(e.model.toString())) {
                        models.push(e.model.toString());
                        return e;
                    }
                } else if (!machines.includes(e.machineNum.toString()) && fieldType === 'addmachineNum') {
                    if (regex.test(e.machineNum)) {
                        machines.push(e.machineNum.toString())
                        return e;
                    }
                }
            });
        }
        if (fieldType && fieldType.includes('search')) {
            filtered = mapEquipmentList.filter(e => {
                if (!models.includes(e.model.toString()) && fieldType === 'searchmodel') {
                    if (regex.test(e.model.toString())) {
                        models.push(e.model.toString());
                        return e;
                    }
                } else if (!machines.includes(e.machineNum.toString()) && fieldType === 'searchmachineNum') {
                    if (regex.test(e.machineNum.toString())) {
                        machines.push(e.machineNum.toString())
                        return e;
                    }
                }
            });
        }
        
        this.setState({ searchItems: filtered, keyword: value, fieldType: fieldType });
    }
    goToItem = (attr, fieldType) => {
        //setting value
        fieldType = this.state.fieldType;
        if(fieldType && fieldType.includes('add')){
            fieldType = fieldType.replace('add','');
        this.props.dispatch(change('AddCustomMapForm', fieldType, attr));
       
        }else{
            fieldType = fieldType.replace('search','');
            this.props.dispatch(change('SearchCustomMapForm', fieldType, attr));
            
    }
    this.setState({ keyword: "", searchItems: [], fieldType: "" });
    }
    componentWillMount(){
        this.getAddedMapData({page:1});   
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.initializeTab != this.props.initializeTab) {   
            this.onPageChange(1);
            this.setState({qs:{}}); 
            this.getAddedMapData({page:1});
        }
    }

    confirmnDelete = (user) => {
        this.props.setConfirmBox({ modal: { open: true, data: user, title: "Delete Record", content: `Do you really want to delete this record?`, done: this.deleteRecord }});
    }

    deleteRecord = (item) =>{
        /** show loader */
        this.props.setLoader({loader:{open: true}});

        return new Promise((resolve, reject) => {
            deleteMap(item.listId)
                .then(result => {
                    /** resolve promise */
                    /** close loader */
                    this.props.setLoader({ loader: { open: false } });
                    /** dispatch action to reload data */
                    this.getAddedMapData({page: this.state.activePage, qs:this.state.qs});
                    resolve({ toastr: { message: "Record has been deleted.", type: "success" } });
                })
                .catch(error => {
                    /** close loader */
                    this.props.setLoader({ loader: { open: false } });
                    setToastr({ toastr: { message: "This request couldn't be completed. Try again later.", type: "error" } });
                    /** reject promise */
                    reject(false);
                })
        });
    }

    getAddedMapData = (req) => {
        this.props.setLoader({ loader: {open:true}});

        getCustomAddedMap(req)
        .then(result => {
            this.props.setLoader({ loader: {open:false}});

            this.setState({
                maps: (result.data && result.data.mapRecords) || [],
                activePage: this.state.activePage || 1,
                total: (result.data.countResult && result.data.countResult.totalCounts) ? result.data.countResult.totalCounts : this.state.total 
            });
        })
        .catch(err => this.props.setLoader({ loader: {open:false}}));
    }

    onPageChange = (page) => {
        this.setState({activePage: page || 1}, () => {
            this.getAddedMapData({page, qs:this.state.qs});
        });  
    }

    editRecord = item => {
        this.setState({ edit: item });
        this.props.initialize(item);
        window.scrollTo(0, 0);
    }


    cancelUpdate = () => {
        this.setState({ edit: null });
        this.props.initialize({machineNum:null, model:null, map1Id:null, map2Id:null});
    }

    updateCustomMap = (values) => {
        let {machineNum, model, map1Id, map2Id, listId} = values;
        
        this.props.setLoader({ loader: {open: true}});
        updateMap({machineNum, model, map1Id, map2Id}, listId)
        .then(response => {
            if(response.data){
                this.props.setToastr({ toastr: { message:"Map Data has been updated.", type:"success" }});
                this.setState({edit: null});
                this.props.initialize({machineNum:null, model:null, map1Id:null, map2Id:null});
                this.getAddedMapData({page: this.state.activePage, qs:this.state.qs});
            }else{
                throw new Error();
            }
            this.props.setLoader({ loader: {open: false}});
        })
        .catch(error => {
            this.props.setToastr({toastr:{type: "error", message:error || "Something went wrong."}});
            this.props.setLoader({ loader: {open: false}});            
        })
    }

    onSubmit = (values) => {
        values.map1Id = parseInt(values.map1Id);
        values.map2Id = parseInt(values.map2Id);

        if(this.state.edit){
            /* update the record */
            this.updateCustomMap(values);
            return false;
        }

        this.props.setLoader({ loader: {open: true}});
        saveMap(values)
        .then(response => {
            if(response.data){
                this.props.setToastr({ toastr: { message:"Custom Map has been added.", type:"success" }});
                //this.props.setTab(1);
                this.props.dispatch(reset(form));
                this.getAddedMapData({page: this.state.activePage, qs:this.state.qs});
            }else{
                throw new Error();
            }
            this.props.setLoader({ loader: {open: false}});
        })
        .catch(error => {
            this.props.setToastr({toastr:{type: "error", message:error || "Something went wrong. Try again later."}});
            this.props.setLoader({ loader: {open: false}});            
        })
    }
    
    onSearch = (qs) => {
        if(!Object.keys(qs).length) return false;

        this.props.setLoader({ loader: {open: true}});
        this.setState({ qs });

        this.getAddedMapData({page:1, qs });
    }

    clearSearch = () => {
        this.setState({qs:{}}); 
        this.getAddedMapData({page:1});
    }

    handleKeyDown =(event) =>{
        if(event.key ==='Enter'){
            event.preventDefault();
        }
    }

    render(){
        const { handleSubmit, submitting } = this.props, {maps, activePage, total, edit,keyword,searchItems,fieldType} = this.state;
        return(
            <Fragment>
                <h4>Add Custom Map</h4>

                <form name={form} id={form} autoComplete='off' onSubmit={handleSubmit(this.onSubmit)} onKeyDown={this.handleKeyDown}>
                    <Row className="members align-items-center form-srch">
                        <Col sm="12">

                            <div className="row profile-asset">
                                

                                <div className="col-sm-2">Modellll <span className='required'>*</span>
                                    <Field name="model" id='addmodel' component={renderTextField} className='form-control' type="text" validate={[required]} onChange={this.getEquipmentList} />
                                    {keyword.length > 0 && searchItems.length > 0 && fieldType === 'addmodel' &&
                                        <div className='search-area'>
                                            <Card className='search-card'>
                                                <CardBody>
                                                    <ul className="list-group list-group-flush">
                                                        {searchItems.map((item, i) => {
                                                            return <ItemSelection key={i} attr={{ item }} goToItem={this.goToItem} fieldType={'model'} />;
                                                        })}
                                                    </ul>
                                                </CardBody>
                                            </Card>
                                        </div>}
                                    {keyword.length > 0 && searchItems.length == 0 && fieldType === 'addmodel' &&
                                        <div className='search-area'>
                                            <Card className='search-card'>
                                                <CardBody>
                                                    <ul className="list-group list-group-flush">
                                                        <li className="list-group-item pointer" >
                                                            <Row>
                                                                <Col sm='12' className='no-padd-lr'>
                                                                    <span>No record found</span>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                    </ul>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    }
                                </div>

                                <div className="col-sm-2">Machine Number <span className='required'>*</span>
                                    <Field name="machineNum" id="addmachineNum" component={renderTextField} className='form-control' type="text" validate={[required]} onChange={this.getEquipmentList} />
                                    {keyword.length > 0 && searchItems.length > 0 && fieldType === 'addmachineNum' && <div className='search-area'>

                                        <Card className='search-card'>
                                            <CardBody>
                                                <ul className="list-group list-group-flush">
                                                    {searchItems.map((item, i) => {
                                                        return <ItemSelection key={i} attr={{ item }} goToItem={this.goToItem} fieldType={'machineNum'} />;
                                                    })}
                                                </ul>
                                            </CardBody>
                                        </Card>
                                    </div>}
                                    {keyword.length > 0 && searchItems.length == 0 && fieldType === 'addmachineNum' &&
                                        <div className='search-area'>
                                            <Card className='search-card'>
                                                <CardBody>
                                                    <ul className="list-group list-group-flush">
                                                        <li className="list-group-item pointer" >
                                                            <Row>
                                                                <Col sm='12' className='no-padd-lr'>
                                                                    <span>No record found</span>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                    </ul>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    }
                                </div>

                                <div className="col-sm-2">Map 1 <span className='required'>*</span>
                                    <Field name="map1Id" component={renderTextField} className='form-control' type="text" validate={[required, onlyNumbers]}/>
                                </div>

                                <div className="col-sm-2">Map 2 <span className='required'>*</span>
                                    <Field name="map2Id" component={renderTextField} className='form-control' type="text" validate={[required, onlyNumbers]}/>
                                </div>

                                <div className="col-sm-1">
                                    <button type="submit" className="btn btn-primary srch-btn" disabled={submitting}>{edit ? "Update" : "Save"}</button>
                                </div>

                                {edit && <div className="col-sm-2"> &nbsp;&nbsp;&nbsp;
                                    <button type="button" className="btn btn-danger srch-btn" disabled={submitting} onClick={() => this.cancelUpdate()}>Cancel</button> 
                                </div>}
                            </div>
                        </Col>
                    </Row>
                </form>

                <hr />

                <h4>Search Map</h4>
                <SearchFormComponent onSearch={this.onSearch} qs={this.state.qs} clearSearch={this.clearSearch} getEquipmentList={this.getEquipmentList} searchItems={searchItems} goToItem={this.goToItem} keyword={keyword} fieldType={fieldType}/>

                {maps && <table className="tabledashboard table table-striped table-advance table-hover m-top-30">
                    <thead>
                        <tr>
                            <th>Model</th>
                            <th>Machine Number</th>
                            <th>Map1</th>
                            <th>Map2</th>
                            <th>List Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {maps.length>0 && maps.map((item,i) => (
                            <tr key={item.model+i+1}>
                                <td>{item.model || "NA"}</td>
                                <td>{item.machineNum || "NA"}</td>
                                <td>{item.map1Id || "NA"}</td>
                                <td>{item.map2Id || "NA"}</td>
                                <td><Moment format="DD MMM, YYYY hh:mm A">{item.listDate}</Moment></td>
                                <td>
                                    <FaPencilAlt onClick={() => this.editRecord(item)}/>
                                    &nbsp; 
                                    &nbsp; 
                                    <FaTrashAlt onClick={() => this.confirmnDelete(item)}/>
                                </td>
                            </tr>
                        ))}

                        {!maps.length && <tr><td align="center" colSpan="6">No Data Found</td></tr>}
                        
                    </tbody>
                </table>}

                <Pagination 
                    limit={LIMIT}
                    total={total}
                    activePage={activePage}
                    onChange={this.onPageChange}
                    data={maps || []}
                />
            </Fragment>
        );
    }
}


let formComponent = reduxForm({ form })(AddCustomMapComponent);
let mapDispatchToProps = { setLoader, setToastr, setConfirmBox };

export default connect(null, mapDispatchToProps)(formComponent);