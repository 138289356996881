import React, { Component, Fragment } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from 'classnames';
import { FaTable, FaPlusSquare, FaCloudUploadAlt } from "react-icons/fa";
import ImportCustomMap from "./ImportCustomMap";
import AddCustomMap from "./AddCustomMap";
import RegisteredMaps from "./RegisteredMaps";
import CustomMapList from "./CustomMapList";
import {getCustomMap} from "../../../api/admin/custom-map";
import {getCustomMapEquipmentList,getSavedCustomMapEquipmentList} from "../../../actions/admin/customMap"
import { setLoader, setToastr } from "../../../actions/Application";
import {connect} from "react-redux";
import "./style.scss";

class CustomMapComponent extends Component{

    constructor(props){
        super(props);

        this.state = {
            maps: null,
            activePage: 1,
            activeTab: 2,
            total: 0,
            initializeTab: true,
            initializeTabOne: true,
            initializeTabTwo: true,
        }

        this.toggle = this.toggle.bind(this);
        this.getMapData = this.getMapData.bind(this);
        this.onClick = this.onClick.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
    }

    getMapData(data){
        this.props.setLoader({ loader: {open: true}});
        getCustomMap({page:data.activePage || 1, qs:`modelId=${data.model}&machineNum=${data.machineNum}`})
        .then(result => {
            if(result.data && result.data.hsitoryMapListObj && result.data.hsitoryMapListObj.constructor === Array){
                this.setState({
                    maps: result.data.hsitoryMapListObj,
                    activePage: data.activePage || 1,
                    total: (result.data.countResult && result.data.countResult.totalCounts) ? result.data.countResult.totalCounts : this.state.total
                });
            }
            this.props.setLoader({ loader: {open: false}});

        })
        .catch(error => {
            this.props.setToastr({toastr:{type: "error", message:error || "Something went wrong."}});
            this.props.setLoader({ loader: {open: false}});            
        })
    }

    onClick(data) {
        if (data.model && data.machineNum) {
            this.getMapData(data);
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
        if (tab === 3) {
            let { initializeTab } = this.state;
            this.setState({ initializeTab: !initializeTab });
        } else if (tab === 1) {
            let { initializeTabOne } = this.state;
            this.setState({ maps: null, initializeTabOne: !initializeTabOne });
        } else if (tab === 2) {
            let { initializeTabTwo } = this.state;
            this.setState({ initializeTabTwo: !initializeTabTwo })
        }
    }

    componentDidMount() {
        this.props.getCustomMapEquipmentList();
        this.props.getSavedCustomMapEquipmentList();
    }

    clearSearch() {
        this.setState({ maps: null });
    }

    render(){
        let { maps, activePage, total } = this.state;
        return(
            <Fragment>
                <section className='reports repo'>
                <Nav tabs>
                    
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === 2 }) }
                            onClick={() => { this.toggle(2); } }
                            >
                            <div>
                                <FaTable />
                                <div className="tabheading">Current Map Status</div>
                            </div>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === 1 }) }
                            onClick={() => { this.toggle(1); } }
                            >
                            <div>
                                <FaTable />
                                <div className="tabheading">Equipment Map history</div>
                            </div>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === 3 }) }
                            onClick={() => { this.toggle(3); } }
                            >
                            <div>
                            <FaPlusSquare />
                                <div className="tabheading">Add Custom Map</div>
                            </div>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === 4 }) }
                            onClick={() => { this.toggle(4); } }
                            >
                            <div>
                            <FaCloudUploadAlt />
                                <div className="tabheading">Import Custom Map </div>
                            </div>
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId={1}>
                        <CustomMapList setTab={this.toggle} maps={maps} activePage={activePage} total={total} onClick={this.onClick} mapEquipmentList={this.props.customMapHistEqList} initializeTab={this.state.initializeTabOne} clearSearch={this.clearSearch}/>
                    </TabPane>

                    <TabPane tabId={2}>
                        <RegisteredMaps setTab={this.toggle} initializeTab={this.state.initializeTabTwo}/>
                    </TabPane>
                    
                    <TabPane tabId={3}>
                        <AddCustomMap setTab={this.toggle} initializeTab={this.state.initializeTab} mapEquipmentList={this.props.customMapHistEqList} newCustomMapEqList ={this.props.newCustomMapEqList} />
                    </TabPane>

                    <TabPane tabId={4}>
                        <ImportCustomMap />
                    </TabPane>
                </TabContent>
                
                </section>
            </Fragment>
        );
    }
}
let mapDispatchToProps = { setLoader, setToastr, getCustomMapEquipmentList,getSavedCustomMapEquipmentList };
const mapStateToProps = state => {
    return ({
        customMapHistEqList: state.customMap && state.customMap.customMapHistEqList  && state.customMap.customMapHistEqList.data,
        newCustomMapEqList: state.customMap && state.customMap.newCustomMapEqList && state.customMap.newCustomMapEqList.data

    });
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomMapComponent);