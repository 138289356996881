import React, { Component } from 'react';
import './style.scss';
import cal from '../../../assets/images/admin/calender.svg'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { FaAngleDown, FaSearch, FaAlignJustify } from 'react-icons/fa'
import 'bootstrap-daterangepicker/daterangepicker.css';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

/** Icons */
import NotStarted from "../../../assets/images/admin/circle.png";
import CallCompleted from "../../../assets/images/admin/completed-green.svg";
import TechnicalError from "../../../assets/images/admin/exclamation.jpg";
import CallScheduled from "../../../assets/images/admin/icon-green.png";
import InProgress from "../../../assets/images/admin/in-progress.svg";

/* A Equipment Search component */
class EquipmentSearchComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            alertData: [],
            status: undefined,
            imgs : undefined,
            apply : false,
            cancel :true,
            daterange: {
                startDate: moment().format("MM-DD-YYYY"),
                endDate: moment().format("MM-DD-YYYY")
            }
        };

        this.setStatus = this.setStatus.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.searchData = this.searchData.bind(this);
        this.onCancel = this.onCancel.bind(this);        
    }

    onDateChange(event, picker){
        let startDate = moment(picker.startDate).format("MM-DD-YYYY"),
        endDate = moment(picker.endDate).format("MM-DD-YYYY");
        this.setState({ daterange: {startDate, endDate}, apply : true, cancel :false });
    }

    setStatus(e, status){
        this.setState({ status,
            imgs : e });
    }

    searchData(){
        let { status, daterange } = this.state;
        this.props.searchData({
            status : (status === 'All') ? undefined :status,
            startDate : (this.state.cancel) ? undefined : moment(daterange.startDate).format("YYYY-MM-DD"),
            endDate : (this.state.cancel) ? undefined : moment(daterange.endDate).format("YYYY-MM-DD")
        })
    }

    onCancel(event,picker){
        let startDate = moment().format("MM-DD-YYYY"),
        endDate = moment().format("MM-DD-YYYY");
        this.setState({ daterange: {startDate, endDate}, apply : false, cancel :true });
        picker.setStartDate(startDate);
        picker.setEndDate(endDate);
        picker.element.val(`${startDate} - ${endDate}`);
    }

    render() {
        const {status, imgs, daterange} = this.state;
        let opt = {
            opens: "left",
            startDate: daterange.startDate,
            endDate: daterange.endDate,
            maxDate: moment().format("MM-DD-YYYY"),
            showDropdowns: true,
            cancelClass: "btn-danger cancelbtn ",
        }

        //if(!this.props.isService) opt.minDate = moment().subtract(90, "days").format("MM-DD-YYYY");
        
        return (
            <div className = "searchcomponent float-right">
                
                <DateRangePicker initialSettings={opt} onApply={this.onDateChange} onCancel={this.onCancel} >
                    <img src={ cal } alt="calender" className='pointer'/>
                </DateRangePicker>

                <UncontrolledDropdown className="float-left">
                    <div>
                        <DropdownToggle tag="button" className="btn filtertype btn-link btn-outline-secondary pl-4"> <FaAngleDown/>{ imgs } {  status || 'Search By Status' } </DropdownToggle>                        
                    </div>
                    <DropdownMenu>
                        <DropdownItem onClick={() => this.setStatus(<FaAlignJustify />,"All")}><FaAlignJustify className="filtericon" /> <span className='highlighted'>All</span></DropdownItem>

                        <DropdownItem onClick={() => this.setStatus(<img src={CallCompleted} alt="Completed"/>,"Completed")}><img src={CallCompleted}  alt="Completed" /> <span className='highlighted'>Completed</span></DropdownItem>

                        <DropdownItem onClick={() => this.setStatus(<img src={InProgress} alt="In Progress" />,"In Progress")}><img src={InProgress} alt="In Progress" /> <span className='highlighted'>In Progress</span></DropdownItem>

                        <DropdownItem onClick={() => this.setStatus(<img src={CallScheduled} alt="Call Scheduled" />,"Call Scheduled")}><img src={CallScheduled} alt="Call Scheduled" /> <span className='highlighted'>Call Scheduled</span></DropdownItem>

                        <DropdownItem onClick={() => this.setStatus(<img src={TechnicalError} alt="Technical Error" />,"Technical Error")}><img src={TechnicalError} alt="Technical Error" /> <span className='highlighted'>Technical Error</span></DropdownItem>

                        <DropdownItem onClick={() => this.setStatus(<img src={NotStarted} alt="Started" />,"Started")}><img src={NotStarted} alt="Started" /> <span className='highlighted'>Started</span></DropdownItem>

                    </DropdownMenu>
                </UncontrolledDropdown>
                <button onClick={this.searchData} className="btnsearch float-left"><FaSearch/></button>
                
            </div>
        );
    }
}

export default EquipmentSearchComponent;
