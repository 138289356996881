import React from 'react';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import ReactDOM from "react-dom";
import session from "./session"
/*Setting up interceptors with axios*/
/*it supports add/remove interceptors - 2017dec*/
// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    let token;
    if((config.url.indexOf("/admin/") >= 0) || (config.url.indexOf("admin.") >= 0)){
        //token = JSON.parse(localStorage.getItem('ADMINTOKEN'));
        if(session.get('ADMINTOKEN', 'cookie')){
            token = session.get('ADMINTOKEN', 'cookie');
        }
    }else if(config.url.indexOf("/sp/") >= 0){
        //token = JSON.parse(localStorage.getItem('SERVPROVTOKEN'));
        if(session.get('ADMINTOKEN', 'cookie')){
            token = session.get('ADMINTOKEN', 'cookie');
        }
    }else{
        token = JSON.parse(localStorage.getItem('TOKEN'));
    }

    let { withoutToken } = config.headers;
    if (token) {
        /** appending auth token */
        if (!withoutToken) {
            config.headers = { "authorizationToken": `${token.token}` }
        }
    }

    /** remove withoutToken key */
    delete config.headers.withoutToken;

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
})

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Do something with response data
    return {
        statusCode: response.status,
        data: (typeof response.data === 'boolean' && response.data === false ? 'false' : response.data) || []
    };
}, function (error) {
    if (!error.response && error.message === 'Network Error') {
        //alert("Couldn't connect to server.");

        ReactDOM.render(<ToastContainer hideProgressBar closeOnClick={false} />, document.getElementById('toastr'));

        toast("Couldn't connect to server. Please try again later.", { type: "info" });
        return Promise.reject();
    }
    // Do something with response error
    return Promise.reject(error.response.data);
});


export default class HTTP {
    static Request(method, url, data = null, withoutToken = false) {
        return new Promise((resolve, reject) => {
            let request = {
                method: method,
                url: url,
                [method.toUpperCase() === 'GET' ? "params" : "data"]: data
            };

            if (withoutToken) {
                request.headers = {
                    withoutToken: withoutToken
                }
            }

            axios(request)
                .then(response => resolve(response))
                .catch(error => reject(error));
        });
    }
}