import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom'
import moment from 'moment';
import { connect } from "react-redux";
import ButtonField  from '../../../components/shared/ButtonField';
import Loaders from '../../../messages/loaders/Loaders';
import {Alert, Row, Col, Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {FaExclamation, FaAngleDown} from 'react-icons/fa';
import TopTabingBar  from '../../../components/service-provider/TopTabingBar';
/** Icons */
import NotStarted from "../../../assets/images/admin/circle.png";
import Completed from "../../../assets/images/admin/completed-green.svg";
import TechnicalError from "../../../assets/images/admin/exclamation.jpg";
import CallScheduled from "../../../assets/images/admin/icon-green.png";
import InProgress from "../../../assets/images/admin/in-progress.svg";
import './style.scss';
import Moment from "react-moment";
import { setLoader, setToastr, setConfirmBox } from "../../../actions/Application";
//import session from "../../../services/session";

/* A Equipment Alert Detail component */
class EquipmentAlertDetailComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            alerId: undefined,
            alertstatus: undefined,
            alertData: [],
            supportData : [],
            showLoader : true,
            status : null,
            imgs : null,
            notifyType: 'success',
            notifyText: 'Hey! details updated',
            updated : false,
            supportUpdateData : {
                "eventId" : null,
                "currentStatus" : null,
                "supportResult" : null,
            }
        };

    }
    componentWillMount() {
        this.props.getData()

        if(!localStorage.getItem('equipmentAlertListData')){
            this.props.history.push("/admin/equipment-alerts");
            return false;
        }

        const alertData = JSON.parse(localStorage.getItem('equipmentAlertListData')).equipmentAlertListData.eventList.filter((val)=>{
            return (val.eventId === this.props.match.params.alerId)
        });

        var status = undefined, imgs = undefined;
        if(alertData && alertData.length > 0){
            let data = alertData[0];
            if(data.currentStatus && data.currentStatus!=='New'){
                status =  (data.currentStatus).replace(/\s/g,'').toLowerCase();
                imgs = <img src={this.getStatusIcon()[status]} alt={status} />;
                status =  data.currentStatus;
            }
        }else{
                this.props.history.push(`/service/equipment-alerts`);
           
        }

        this.setState({ alertData, status, imgs });
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps && nextProps.match && nextProps.match.params && nextProps.match.params.alerId){
            if(this.state.alerId !== nextProps.match.params.alerId){
                const alertData = JSON.parse(localStorage.getItem('equipmentAlertListData')).equipmentAlertListData.eventList.filter((val)=>{
                    return (val.eventId === nextProps.match.params.alerId)
                });

                this.setState({ alerId: nextProps.match.params.alerId, alertData });

            }else{
                

                this.setState({alerId: nextProps.match.params.alerId});
            }
        }
        

        if (this.props.supportData !== nextProps.supportData) {
            if(nextProps.supportData && nextProps.supportData.SET_EQUIPMENTS_SUPPORT_DATA ){
                if(this.props.supportData.SET_EQUIPMENTS_SUPPORT_DATA !== nextProps.supportData.SET_EQUIPMENTS_SUPPORT_DATA){
                    if(this.props.isService){
                        this.setState({
                            supportData: nextProps.supportData.SET_EQUIPMENTS_SUPPORT_DATA
                        });
                    }else{
                        if(nextProps.supportData.SET_EQUIPMENTS_SUPPORT_DATA.equipmentEvents){
                            let currentStatus = nextProps.supportData.SET_EQUIPMENTS_SUPPORT_DATA.equipmentEvents && nextProps.supportData.SET_EQUIPMENTS_SUPPORT_DATA.equipmentEvents.currentStatus;
                            let status =  (currentStatus).replace(/\s/g,'').toLowerCase();
                            let imgs = <img src={this.getStatusIcon()[status]} alt={status} />;
                            this.setState({
                                supportData: nextProps.supportData.SET_EQUIPMENTS_SUPPORT_DATA.eventSupportInfoList,
                                alertData:  [nextProps.supportData.SET_EQUIPMENTS_SUPPORT_DATA.equipmentEvents],
                                status : nextProps.supportData.SET_EQUIPMENTS_SUPPORT_DATA.equipmentEvents && nextProps.supportData.SET_EQUIPMENTS_SUPPORT_DATA.equipmentEvents.currentStatus,
                                imgs : imgs
                            });
                        }
                    }
                }
                this.setState({
                    showLoader : nextProps.supportData.isSending,
                });
            }

            if(nextProps.supportData.error === true){
                if(!this.props.isService){
                    this.props.history.push(`/service-provider/equipment-alerts`);
                }else{
                    this.props.history.push(`/admin/equipment-alerts`);
                }
            }
            
            if(this.state.updated && nextProps.supportData.SET_EQUIPMENTS_SUPPORT_INFO_DATA){
                this.setState({
                    alertstatus : this.state.status,
                    notify: true,
                    notifyType: 'success',
                    notifyText: 'Support Info added !!'
                });
            }
        }
    }

    setStatus(e, status){
        this.setState({ status,
            imgs : e });
    }
    getStatusIcon(){
        return {
            completed : Completed,
            inprogress : InProgress,
            callscheduled : CallScheduled,
            technicalerror : TechnicalError,
            notstarted : NotStarted,
            started : NotStarted
        }
    }

    handleClick(){
        let supportResult = document.getElementById('supportresult')
        let currentStatus = this.state.status, realClass = 'btn filtertype btn-link btn-outline-secondary pl-4'
        if(currentStatus === null){
            document.getElementById('statusbtn').className = `${realClass} border-danger`
        }else if(supportResult.value === "" || supportResult.value === undefined){
            this.props.setToastr({ toastr: { message: "Please enter support comments to proceed", type: "error" } });
            supportResult.className = 'border-danger'
            document.getElementById('statusbtn').className = realClass
        }else{
            /** Handle error CSS */
            supportResult.className = ''
            /** Get user data and prepare post data*/
            //let byteUserData = CryptoJS.AES.decrypt(localStorage.getItem('serv_prov_authenticated').toString(), hashKey), 
            //userData = byteUserData.toString(CryptoJS.enc.Utf8), 
            let userData = (this.props.profile && this.props.profile.name) ? this.props.profile.name : "User",
            submitData = {eventId : this.state.alertData[0].eventId, supportResult : supportResult.value,currentStatus : currentStatus},
            newdata = JSON.parse( JSON.stringify(this.state.supportData))
            
            /** Submit user data */
            this.props.handleClick({eventId : this.state.alertData[0].eventId, supportResult : supportResult.value,currentStatus : currentStatus});

            /** Manupuate suport info data */
            submitData.statusDateTime = moment().format('DD MMM YYYY HH:mm:ss');
            submitData.responder = userData
            newdata.unshift(submitData);
            document.getElementById("supportresult").value = ""
            //this.setState({ supportData : newdata, updated : true});
            setTimeout(() => {
                this.props.getSupportInfos();
            }, 200);
            this.setState({ updated : true});
        }
    }

     onDismiss() {
        this.setState({ notify: false });
    }

    render() {
        const { translate,isService } = this.props;
        const {status, imgs, alertstatus} = this.state;

        const data = (this.state.alertData.length > 0) ? this.state.alertData[0] : { currentStatus : null };
        if(data && data.currentStatus === null){
           data.currentStatus = alertstatus || "Started"; 
        }else{
            data.currentStatus = alertstatus || data.currentStatus;
        }

        return (
            <div className="eqalertdetailcontainer">
                { this.state.showLoader && <Loaders
                    loadingText={ `Please Wait...` }
                    modalOpen={ true }
                    className={ `loading-contents` }
                    color={ '#08a5e1' }
                    /> 
                } 
                 <TopTabingBar active = {1}/> 
                <div>
                    <div className={`callstatus ${data.currentStatus !== null && (data.currentStatus).replace(/\s/g,'').toLowerCase()}`}>CURRENT STATUS: {data.currentStatus !== 'New' && <img alt="status" src={ this.getStatusIcon()[data.currentStatus !== null  && (data.currentStatus).replace(/\s/g,'').toLowerCase()] }/>} {alertstatus || data.currentStatus || ' '}</div>
                    <Col lg="12">
                         <div className="header-text">{`Alert Details - Equipment `}</div> 
                        <Row>
                            <Col sm="6" className="eqdetailbox">
                                <div className="detailcover">
                                    <div>{ translate('occurredon') }</div><div>
                                        {data.eventDate ? 
                                            <Moment tz="Asia/Kolkata" format="DD MMM YYYY HH:mm:ss">{data.eventDate}</Moment>
                                            : " "
                                        }
                                    </div>
                                </div>
                                <div className="detailcover">
                                    <div>{ translate('type') }</div><div>{data.eventType}</div>
                                </div>
                                <div className="detailcover">
                                    <div>{ translate('urgency') }</div><div><FaExclamation className={`smallurgency urgencyColor${data.eventUrgency}`}/> {data.eventUrgency==="1"? "High" : (data.eventUrgency === '2' ? "Medium" : "Low")} </div>
                                </div>
                                <div className="detailcover">
                                    <div>{ translate('description') }</div><div className='min-desc'>{data.eventDescription}</div>
                                </div>
                                
                                    
                                    <Fragment> 
                                        <div className="detailcover">
                                            <div>{ translate('owner') }</div><div>{data.owner}</div>
                                        </div>
                                        <div className="detailcover">
                                            <div>{ translate('emergencyContact') }</div><div>{data.emergencyContact === 't' ? "Yes" : "No"}</div>
                                        </div>
                                    </Fragment>
                                
                            </Col>
                            <Col sm="6" className="eqdetailbox">
                            {data && data.status && data.status.toUpperCase() == 'TE' &&
                                     <div className="detailcover">
                                         <div>{translate('numberPlate')}</div><div>{(data.numberPlate ? data.numberPlate.toUpperCase() : " ")}</div>
                                     </div>
	                                 }
                                <div className="detailcover">
                                    <div>{translate('model') }</div><div>{data.model}</div>
                                </div>
                                <div className="detailcover">
                                    <div>{translate('machineno') } </div><div>{data.machineNum}</div>
                                </div>
                                
                                    <div className="detailcover">
                                            <div>{ translate('owner') }</div><div>{data.owner}</div>
                                        </div>
                                    <Fragment> 
                                        <div className="detailcover">
                                            <div>{translate('equipmentType') }</div><div>{data.equipmentType || "TRACTOR"}</div>
                                        </div>
                                        <div className="detailcover">
                                            <div>{translate('emergencyContactNumber') }</div><div>{data.emergencyContactNumber}</div>
                                        </div>
                                    </Fragment>
                            </Col>
                            <div className="clearfix"></div>
                        </Row>
                    </Col>
                </div>
                <div className="clearfix"/>
                <div className="supportinfo m-3 p-1">Support Info</div>
                    <div className="updatesupportinfo">
                        { this.state.notify && <Alert  color={this.state.notifyType} isOpen={this.state.notify} toggle={this.onDismiss.bind(this)}> {this.state.notifyText}</Alert> }
                        
                        <Row>
                        <Col sm={4} md={2} className='text-uppercase highlighted'>Update Status</Col>
                            <Col sm={4} md={2} className='no-padd-left'>
                                <div className='highlighted mb-2'>Current status</div>
                                <UncontrolledDropdown className="float-left">
                                    <div>
                                        <DropdownToggle tag="button" className="btn filtertype btn-link btn-outline-secondary pl-4" id="statusbtn"> <FaAngleDown/>{ imgs } {  status || 'Select Status' } </DropdownToggle>                        
                                    </div>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => this.setStatus(<img src={Completed} alt="completed" />,"Completed")}><img src={Completed}  alt="completed" /> <span className='highlighted'>Completed</span></DropdownItem>
                                        <DropdownItem onClick={() => this.setStatus(<img src={InProgress} alt="inprogress" />,"In Progress")}><img src={InProgress} alt="inprogress"/> <span className='highlighted'>In Progress</span></DropdownItem>
                                        <DropdownItem onClick={() => this.setStatus(<img src={CallScheduled} alt="callscheduled" />,"Call Scheduled")}><img src={CallScheduled} alt="callscheduled" /> <span className='highlighted'>Call Scheduled</span></DropdownItem>
                                        <DropdownItem onClick={() => this.setStatus(<img src={TechnicalError} alt="TechnicalError"/>,"Technical Error")}><img src={TechnicalError} alt="TechnicalError" /> <span className='highlighted'>Technical Error</span></DropdownItem>
                                        <DropdownItem onClick={() => this.setStatus(<img src={NotStarted} alt="Started" />,"Started")}><img src={NotStarted} alt="Started" /> <span className='highlighted'>Started</span></DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Col>
                            <Col sm={8} md={8}>
                                <label className='highlighted' >Support Comments</label><span className='required'>*</span>
                                <textarea id="supportresult" maxLength="250"></textarea>
                                <ButtonField className="addbtn btn-primary" handleClick= {this.handleClick.bind(this)} clickEvent={true} buttonText="Add"/>
                            </Col>
                        </Row>
                    </div>
                <div className="col-lg-12">
                    <div className={this.state.supportData && this.state.supportData.length >= 8 ? `table-wrapper` : ``}>
                        <Table className="tabledashboard table table-striped table-advance table-hover">
                            <thead>
                                <tr >
                                    <th className="xs-hidden">{translate('status') }</th>
                                    <th className="xs-hidden">{translate('data/time') }</th>
                                    <th className="xs-hidden">{translate('responders') }</th>
                                    <th className="xs-hidden">{translate('supportComments') }</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.getSupportTable() }
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        );
    }

    getSupportTable(){
         let supportData = '';
        if (this.state.supportData.length > 0) {
            supportData = this.state.supportData.map((value, index) => {
                return (
                    <tr key={ index }>
                        <td width="15%">{ value.currentStatus }</td>
                        <td width="20%">{ moment(value.statusDateTime).format('DD MMM YYYY HH:mm:ss')}</td>
                        <td width="15%">{ value.responder }</td>
                        <td width="50%" className='word-break'>{ value.supportResult} </td>
                    </tr>
                );
            });
        } else {
            if(!this.props.isService){
                if(this.props.supportData.SET_EQUIPMENTS_SUPPORT_DATA && this.props.supportData.SET_EQUIPMENTS_SUPPORT_DATA.eventSupportInfoList && this.props.supportData.SET_EQUIPMENTS_SUPPORT_DATA.eventSupportInfoList.length === 0){
                    supportData = <tr className="custom-no-data"><td className="text-center" colSpan={4}> No Data Found </td></tr>;
                }else{
                    supportData = <tr className="custom-no-data"><td className="text-center" colSpan={4}> Loading data..</td></tr>;
                }
            }else{
                if(this.props.supportData.SET_EQUIPMENTS_SUPPORT_DATA && this.props.supportData.SET_EQUIPMENTS_SUPPORT_DATA.length === 0){
                    supportData = <tr className="custom-no-data"><td className="text-center" colSpan={4}> No Data Found </td></tr>;
                }else{
                    supportData = <tr className="custom-no-data"><td className="text-center" colSpan={4}> Loading data..</td></tr>;
                }
            }
        }
        return supportData
    }

    componentDidMount(){
        if(this.props.isService){
            document.getElementsByClassName('header-text')[0].textContent = `Alert Details - Equipment `
        }
                    
        if(this.props.supportData && this.props.supportData.SET_EQUIPMENTS_SUPPORT_DATA){
            this.setState({
                supportData: (this.props.isService) ?this.props.supportData.SET_EQUIPMENTS_SUPPORT_DATA : this.props.supportData.SET_EQUIPMENTS_SUPPORT_DATA.eventSupportInfoList,
                showLoader: this.props.supportData.isSending
            });
        }
    }
}


const mapStateToProps = state => {
    return({ profile: state.spProfile.profile});
}

let mapDispatchToProps = { setLoader, setToastr, setConfirmBox };
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(EquipmentAlertDetailComponent));